import {sweetAlertConstants} from '_constants'

export function sweet_alert(state = {}, action) {
  switch (action.type) {
    case sweetAlertConstants.SUCCESS:
      return {
        type: 'sweet_alert-success',
        options: action.options
      };
    case sweetAlertConstants.DANGER:
      return {
        type: 'sweet_alert-danger',
        options: action.options
      };
    case sweetAlertConstants.WARNING:
      return {
        type: 'sweet_alert-warning',
        options: action.options
      };
    case sweetAlertConstants.INFO:
      return {
        type: 'sweet_alert-info',
        options: action.options
      };
    case sweetAlertConstants.CLEAN:
      return {
        type: 'sweet_alert-clean',
        options: action.options
      };
    case sweetAlertConstants.CLEAR:
      return {};
    default:
      return state
  }
}
