import React from "react"
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

import extendedTablesStyle from "./extendedTablesStyle.jsx"

import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import {configService} from "../../_services";
import {filterSubstring} from "../helper_functions/table_filtering";
import MyDropdown from "../../components/MyDropdown";
import Button from "../../components/CustomButtons";
import {Alert} from "@mui/material";
import FormControl from "@material-ui/core/FormControl";

import Datetime from "react-datetime";
import MyReactTable from "../../components/MyReactTable/MyReactTable";

class Config extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      differences: [],
      device_serial_1: undefined,
      device_serial_2: undefined,
      at_time_device_1: undefined,
      at_time_device_2: undefined,
      compare_result: undefined,
      device_key_1: "",
      device_key_2: "",
      loading_differences: false,
      language: "en"
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  componentDidMount() {
    this._is_mounted = true

    let userLanguage = window.navigator.userLanguage || window.navigator.language;
    let lang = userLanguage.split('-')[0]

    try {
      let moment = require('moment/locale/' + userLanguage)
      moment.locale(userLanguage)
      this.setState({
        language: userLanguage,
      })
    } catch {
      try {
        let moment = require('moment/locale/' + lang)
        moment.locale(lang)
        this.setState({
          language: lang,
        })
      } catch {
        console.error("Could not load locale " + lang)
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  compare_configs() {
    const {device_serial_1, device_serial_2, at_time_device_1, at_time_device_2} = this.state
    this.setState({loading_differences: true, compare_result: undefined})
    configService.compareConfigs(device_serial_1, device_serial_2, at_time_device_1, at_time_device_2, res => {
        let device_key_1 = ""
        let device_key_1_raw = ""
        let device_key_2 = ""
        let device_key_2_raw = ""

        if (res.result.differences.length > 0) {
          let first_diff = res.result.differences[0]

          Object.keys(first_diff).forEach((key, index) => {
            if (key.startsWith(device_serial_1) && device_key_1 === "") {
              device_key_1 = key.replace('/', '&&&')
              device_key_1_raw = key
            } else if (key.startsWith(device_serial_2) && device_key_2 === "") {
              device_key_2 = key.replace('/', '&&&')
              device_key_2_raw = key
            }

          })
        }

        this.setState({
          differences: res.result.differences.map((diff) => {

            let value_1 = diff[device_key_1_raw]
            let value_2 = diff[device_key_2_raw]

            if (typeof value_1 == "boolean")
              value_1 = value_1 ? "True" : "False"

            if (typeof value_2 == "boolean")
              value_2 = value_2 ? "True" : "False"

            if (typeof value_1 == "object" && value_1 !== null)
              value_1 = JSON.stringify(value_1);
            if (typeof value_2 == "object" && value_2 !== null)
              value_2 = JSON.stringify(value_2);

            return {
              "key_value": diff["key_value"],
              [device_key_1]: value_1,
              [device_key_2]: value_2,
            }

          }), loading_differences: false, compare_result: true,
          device_key_1, device_key_2
        })
      },
      reject => {
        this.setState({loading_differences: false, compare_result: false})
      })
  }

  handle_form_input_selector(name, value) {
    this.setState({[name]: value})
  }

  handleDateChangeRaw = (e) => {
    e.preventDefault();
  }

  handle_date_selector(state_name, datetime) {
    this.setState({[state_name]: datetime.toISOString()})
  }

  render() {
    const {all_commissioned_devices} = this.props
    const {
      compare_result,
      differences,
      at_time_device_1,
      at_time_device_2,
      loading_differences,
      device_key_1,
      device_key_2,
      device_serial_1,
      device_serial_2,
      language
    } = this.state

    let device_name_1 = ''
    let device_name_2 = ''

    all_commissioned_devices.forEach((device) => {
      if (device.serial === device_serial_1) {
        device_name_1 = device.name
      }
      if (device.serial === device_serial_2) {
        device_name_2 = device.name
      }
    })

    let creation_time_1 = device_key_1.split("&&&")[1]
    let creation_time_2 = device_key_2.split("&&&")[1]

    let header_1 = device_serial_1 ? (
      <div> {device_name_1} <br/> {creation_time_1 ? new Date(creation_time_1).toLocaleString() : ""} </div>
    ) : (
      'Device 1'
    )

    let header_2 = device_serial_2 ? (
      <div> {device_name_2} <br/> {creation_time_2 ? new Date(creation_time_2).toLocaleString() : ""} </div>
    ) : (
      'Device 2'
    )

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader><h4 style={{color: primaryColor}}><b>{"Compare Configurations"}</b></h4></CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <MyDropdown
                    input_label={"Device 1"}
                    name={"device_serial_1"}
                    value={this.state["device_serial_1"]}
                    default_value={this.state["device_serial_1"]}
                    options={[...all_commissioned_devices.map((item) => {
                      return {id: item.serial, value: item.name}
                    })]}
                    handle_selection={this.handle_form_input_selector.bind(this)}
                  />
                  <br/>
                  <FormControl fullWidth>
                    <Datetime
                      viewMode={"time"}
                      timeConstraints={{
                        hours: {},
                        minutes: {step: 5}
                      }}
                      locale={language}
                      input={false}
                      onChangeRaw={this.handleDateChangeRaw}
                      onChange={this.handle_date_selector.bind(this, "at_time_device_1")}
                      inputProps={{
                        placeholder: "Select Time Device 1",
                      }}
                      initialValue={at_time_device_1}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <MyDropdown
                    input_label={"Device 2"}
                    name={"device_serial_2"}
                    value={this.state["device_serial_2"]}
                    default_value={this.state["device_serial_2"]}
                    options={[...all_commissioned_devices.map((item) => {
                      return {id: item.serial, value: item.name}
                    })]}
                    handle_selection={this.handle_form_input_selector.bind(this)}
                  />
                  <br/>

                  <FormControl fullWidth>
                    <Datetime
                      viewMode={"time"}
                      timeConstraints={{
                        hours: {},
                        minutes: {step: 5}
                      }}
                      locale={language}
                      input={false}
                      onChangeRaw={this.handleDateChangeRaw}
                      onChange={this.handle_date_selector.bind(this, "at_time_device_2")}
                      inputProps={{
                        placeholder: "Select Time Device 2",
                      }}
                      initialValue={at_time_device_2}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Button fullWidth onClick={this.compare_configs.bind(this)} color={"primary"}> Compare </Button>
                </GridItem>

              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader><h4 style={{color: primaryColor}}><b>{"Compare Configurations"}</b></h4></CardHeader>
            <CardBody>
              {compare_result && differences.length === 0 && <Alert severity={"success"}> Configs are the same </Alert>}
              <Alert severity={"info"}> Ignored in following list: <ul>
                <li>/bot/serial</li>
                <li>/cameras/*</li>
                <li>/communication_channels/mqtt/key_file</li>
                <li>/communication_channels/web/robot_name</li>
                <li>/component_serials/*</li>
                <li>/sensors/imu/pitch</li>
                <li>/sensors/imu/roll</li>
                <li>/vehicle/steering_angle_encoder_offset</li>
              </ul></Alert>
              <MyReactTable
                id={"config"}
                data={differences}
                filterable
                loading={loading_differences}
                columns={[
                  {
                    Header: "Parameter Name",
                    accessor: "key_value",
                    filterMethod: (filter, row) => filterSubstring(filter, row),
                  },
                  {
                    Header: header_1,
                    id: "Device 1",
                    accessor: row => row[device_key_1],
                    filterMethod: (filter, row) => filterSubstring(filter, row),
                  },
                  {
                    Header: header_2,
                    id: "Device 2",
                    accessor: row => row[device_key_2],
                    filterMethod: (filter, row) => filterSubstring(filter, row),
                  },

                ]}
                getTrProps={(state, rowInfo, column) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      style: {
                        background: rowInfo.row.level === "fatal" ? "rgba(255, 0, 0, 0.1)" : null
                      }
                    }
                  } else {
                    return {}
                  }
                }}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className=" -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

    )
  }
}


function mapStateToProps(state) {
  const {user} = state.authentication
  const {all_commissioned_devices} = state.device_fleet
  return {
    user, all_commissioned_devices
  }
}


export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(Config))
