import SuperAgent from 'superagent'
import {api_root} from 'index.js'
import {errorHandling, unauthorizedRedirect} from './error.handler.js'
import {getKeycloak} from 'react-router-keycloak';

export const nimbusService = {
  validateKeycloak,
  validateContinentalSync,
  getResultsValidationKeycloak,
  getResultsValidationContinentalSync,
};

function validateContinentalSync(callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/nimbus/continental/validate")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getResultsValidationContinentalSync(per_page, page, validation_id, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/nimbus/continental/results")
  .query({per_page})
  .query({page})
  .query({validation_id})
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function validateKeycloak(callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/nimbus/keycloak/validate")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getResultsValidationKeycloak(per_page, page, validation_id, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/nimbus/keycloak/results")
  .query({per_page})
  .query({page})
  .query({validation_id})
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}
