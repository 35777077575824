import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import {connect} from 'react-redux';


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";

// material-ui icons
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";
import AddAlert from "@material-ui/icons/AddAlert"

// core components
import HeaderLinks from "components/HeaderLinks";
import Button from "components/CustomButtons";
import Snackbar from "components/Snackbar"

import headerStyle from "./headerStyle.jsx";

import {alertActions} from '_actions';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      bc: false,
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.alert && newProps.alert.type) {
      this.showNotification()
    }
  }

  showNotification() {
    if (!this.state.bc) {
      this.setState({bc: true})
      setTimeout(
        function () {
          this.setState({bc: false})
          this.props.dispatch(alertActions.clear())
        }.bind(this),
        6000
      )
    }
  }

  makeBrand() {
    var name;
    this.props.routes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      }
      if (prop.path === this.props.location.pathname) {
        name = prop.name;
      }
      return null;
    });
    if (name) {
      return name;
    } else {
      return "Default Brand Name";
    }
  }

  render() {
    const {classes, color, alert} = this.props;
    const {bc} = this.state

    var alertColor = alert.type !== undefined ? alert.type.toLowerCase().indexOf("success") === -1 ? "warning" : "success" : "warning"
    var alertMessage = alert.message

    const appBarClasses = cx({[" " + classes[color]]: color});
    const sidebarMinimize = classes.sidebarMinimize
    return (
      <AppBar className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          <Hidden smDown implementation="css">
            <div className={sidebarMinimize}>
              {this.props.miniActive ? (
                <Button
                  justIcon
                  round
                  color="white"
                  onClick={this.props.sidebarMinimize}
                >
                  <ViewList className={classes.sidebarMiniIcon}/>
                </Button>
              ) : (
                <Button
                  justIcon
                  round
                  color="white"
                  onClick={this.props.sidebarMinimize}
                >
                  <MoreVert className={classes.sidebarMiniIcon}/>
                </Button>
              )}
            </div>
          </Hidden>
          <div className={classes.flex}>
            {/* Here we create navbar brand, based on route name */}
            <Button href="#" className={classes.title} color="transparent">
              {this.makeBrand()}
            </Button>
          </div>

          <Hidden smDown implementation="css">
            <HeaderLinks/>
          </Hidden>
          <Hidden mdUp implementation="css">
            <Button
              className={classes.appResponsive}
              color="transparent"
              justIcon
              aria-label="open drawer"
              onClick={this.props.handleDrawerToggle}
            >
              <Menu/>
            </Button>
          </Hidden>
        </Toolbar>
        <Snackbar
          place="tc"
          color={alertColor}
          icon={AddAlert}
          message={(<b>{alertMessage}</b>)}
          open={bc}
          closeNotification={() => this.setState({bc: false})}
          close
        />
      </AppBar>
    )
  }
}


Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "octinionLight", "info", "success", "warning", "danger"]),
};

function mapStateToProps(state) {
  const {alert, installation} = state;
  return {
    alert, installation
  }
}

export default withStyles(headerStyle)(connect(mapStateToProps)(Header))
