export function beautify_name(name) {
  if (name === undefined)
    return ''

  if (typeof name !== 'string') {
    if (isNaN(name))
      return ''
    return name
  }
  name = name.toLowerCase()
  const pieces = []
  name.split('_').forEach((piece) => {
    pieces.push(piece.charAt(0).toUpperCase() + piece.slice(1))
  })
  return pieces.join(' ')
}


export function numberWithSpaces(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

export function lowercaseWithSpaces(x) {
  if (x === undefined || x === null) {
    return ""
  }
  let words = []
  x.split("_").forEach((word) => {
    words.push(word.charAt(0) + word.substring(1).toLowerCase())
  })
  return words.join(" ")
}
