import React from "react"
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

import extendedTablesStyle from "./extendedTablesStyle.jsx"

import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import Table from "../../components/Table";
import MyDropdown from "../../components/MyDropdown";
import {incidentService, mapService} from "../../_services";
import ReactTimeAgo from "react-time-ago";
import {Icon} from "leaflet"

import "components/LeafletIndoor/leaflet-indoor"
import "components/LeafletHeat/leaflet-heat"

import {Link} from "react-router-dom";
import queryString from "query-string";
import GreenhouseMap from "../Maps/GreenhouseMap";

function percent2color(percent, min, max) {
  const base = (max - min);

  if (base === 0) {
    percent = 100;
  } else {
    percent = (percent - min) / base * 100;
  }
  let r, g, b = 0;
  if (percent < 50) {
    g = 150;
    r = Math.round(5.1 * percent);
  } else {
    r = 255;
    g = Math.round(510 - 5.10 * percent);
  }
  let h = r * 0x10000 + g * 0x100 + b;
  return '#' + ('000000' + h.toString(16)).slice(-6);
}


class IncidentMaps extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      map_id: "",
      map_version: "2.1.1",
      map_detail: {
        "lanes": {}
      }
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  componentDidMount() {
    this._is_mounted = true
    const {maps} = this.props

    const {map_id} = queryString.parse(this.props.location.search)

    let my_map_id = Object.keys(maps)[0]
    if (map_id !== undefined && map_id !== null) {
      my_map_id = map_id
    }
    this.setState({
      map_id: my_map_id,
      loading_maps: false
    })
  }

  handle_form_input_selector_map_id(name, value) {
    this.setState({map_id: value}, () => {
      this.refresh(value)
    })
  }

  refresh(map_id) {
    mapService.getMapVersions(map_id, res => {
      this.setState({map_version: res.versions[res.versions.length - 1]})
    })
    incidentService.getPerMap(map_id, res => {
      if (res.result === null) {
        this.setState({
          map_detail: {
            "lanes": {}
          }
        })
      } else {
        this.setState({map_detail: res.result})
      }
    })
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  style_function(feature) {
    let color = 'white';
    let fillOpacity = 0.5;
    if (feature.properties.node_type === 'walkway') {
      color = '#0A485B';
      fillOpacity = 0.5;
    } else if (feature.properties.node_type === 'lane') {
      const {map_detail} = this.state
      let lanes = this.get_lanes()
      if (lanes === {}) {
        color = '#989898';
      } else {
        let incidents = lanes[feature.properties.node_id] ? lanes[feature.properties.node_id].length : 0
        let total_lanes = map_detail["total_number_of_incidents"]

        color = percent2color(incidents / total_lanes, 0, 1)
      }
      fillOpacity = 0.5;
    }
    return {
      fillColor: color, fill: true, weight: 1, color: "#666", fillOpacity: fillOpacity
    };
  }

  get_lanes() {
    const {map_detail} = this.state
    if (map_detail === undefined || map_detail === null || map_detail === {})
      return {}

    if (map_detail["lanes"] === undefined || map_detail["lanes"] === null)
      return {}

    return map_detail["lanes"]
  }

  get_markers() {
    let markers = []
    let lanes = this.get_lanes()

    const red_icon = new Icon({
      iconUrl: require('assets/icons/map-marker.svg'),
      iconSize: [20, 70],
      iconAnchor: [10, 53],
      popupAnchor: [0, -20]
    });

    Object.keys(lanes)
    .forEach((lane_id) => {
      let incidents = lanes[lane_id]
      incidents.forEach((incident) => {
        markers.push({
          "key": `marker-${incident["incident_id"]}`,
          "icon": red_icon,
          "popup": <span>
                            ID:
                            <br/>
                            <Link to={`/incidents/all?incident_id=${incident["incident_id"]}`}>
                                {incident["incident_id"]}
                            </Link>
                        </span>,
          "x": incident["x"],
          "y": incident["y"],
        })
      })
    })
    return markers
  }

  render() {
    const {maps} = this.props
    const {map_detail, map_version, map_id} = this.state

    let last_updated = map_detail["calculated_at"]
    let markers = this.get_markers()
    let lanes = this.get_lanes()

    return (<div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardHeader><h4 style={{color: primaryColor}}><b>{"Incidents per lane"}</b><br/>
              {last_updated !== undefined ?
                <p>Calculated at: <ReactTimeAgo date={last_updated} locale="en-UK"/></p> : ""}
            </h4></CardHeader>
            <CardBody>
              <MyDropdown
                input_label={"Map"}
                name={"map_id"}
                value={map_id}
                default_value={map_id}
                options={[...Object.keys(maps).map((map_id_) => {
                  return {id: map_id_, value: maps[map_id_] ? maps[map_id_]["name"] : map_id_}
                })]}
                handle_selection={this.handle_form_input_selector_map_id.bind(this)}
              />

              <Table
                tableHeaderColor="primary"
                style={{fontSize: '10px'}}
                tableData={[[<b>Lane</b>, <b># incidents</b>,
                  <b>Fail percentage</b>,], ...Object.keys(lanes)
                .sort((a, b) => {
                  let lanes_a = lanes[a]
                  let lanes_b = lanes[b]

                  let percent_a = lanes_a.length / map_detail["total_number_of_incidents"]
                  let percent_b = lanes_b.length / map_detail["total_number_of_incidents"]

                  return percent_b - percent_a
                })
                .map((lane_id) => {
                  let incidents = lanes[lane_id]
                  let percent = incidents.length / map_detail["total_number_of_incidents"] * 100
                  percent = Math.round((percent + Number.EPSILON) * 100) / 100
                  return [lane_id, incidents.length + "/ " + map_detail["total_number_of_incidents"] + " incidents", percent + "%"]
                }), ["", "", ""]]}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader><h4 style={{color: primaryColor}}><b>{"Map View"}</b><br/>
              {last_updated !== undefined ?
                <p>Calculated at: <ReactTimeAgo date={last_updated} locale="en-UK"/></p> : ""}
            </h4></CardHeader>
            <CardBody>
              <GreenhouseMap
                map_id={map_id}
                map_version={map_version}
                markers={markers}
                style_function={this.style_function.bind(this)}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>)
  }
}

function mapStateToProps(state) {
  const {maps} = state.location

  return {
    maps
  }
}


export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(IncidentMaps))
