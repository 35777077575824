import SuperAgent from 'superagent'
import {api_root} from 'index.js'
import {errorHandling, unauthorizedRedirect} from './error.handler.js'
import {getKeycloak} from 'react-router-keycloak';

export const deviceService = {
  getDeviceConfig,
  deployDevice,
  getDeviceLogs,
  getDeviceEvents,
  getErrorOverview,
  syncTreatments,
  getDeviceState,
  getDeviceInControl,
  waitOnEvent,
  releaseControl,
  takeControl,
  stopImmediately,
  uploadIncidentData,
  uploadAllIncidentData,
  validateMaps,
  reloadConfig,
  getDeviceActions,
  getDevice,
  getDeviceDecrypted,
  getCredentials,
};

function getDeviceActions(device_serial, action_type, per_page, page, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/device/actions")
  .query({device_serial})
  .query({action_type})
  .query({per_page})
  .query({page})
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getDevice(device_serial, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/device/" + device_serial)
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getDeviceDecrypted(device_serial, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/device/" + device_serial + "/decrypted")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function deployDevice(device_serial, location_id, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .post(api_root + "/device/" + device_serial + "/deploy")
  .send({location_id})
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getCredentials(device_serial, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/device/" + device_serial + "/credentials")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getDeviceConfig(device_serial, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/config")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getDeviceLogs(device_serial, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/logs")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getDeviceEvents(device_serial, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/events")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getErrorOverview(device_serial, prev_hours, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/error_overview/" + prev_hours)
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function syncTreatments(device_serial, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/treatments/sync")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function stopImmediately(device_serial, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/stop_immediately")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function reloadConfig(device_serial, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/reload_config")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function uploadAllIncidentData(device_serial, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/upload_data/all")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function validateMaps(device_serial, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/validate_maps")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function uploadIncidentData(device_serial, database_id, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/upload_data/" + database_id)
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getDeviceState(device_serial, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/state")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getDeviceInControl(device_serial, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/in_control")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function takeControl(device_serial, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/take_control")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function releaseControl(device_serial, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/release_control")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function waitOnEvent(device_serial, event_name, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/wait/" + event_name)
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}