import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

// Assets
import userProfileStyles from "./userProfileStyles.jsx"

// Services
import {deviceService, mapService, treatmentsService} from '_services'
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import Button from "../../components/CustomButtons";
import {HelpOutlineTwoTone} from "@material-ui/icons";
import {Alert} from "@mui/material";
import Check from "@material-ui/icons/CheckCircleTwoTone";
import Error from "@material-ui/icons/ErrorTwoTone";
import Download from "@material-ui/icons/CloudDownloadTwoTone";
import DeviceSelector from "./DeviceSelector";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import ReactTable from "react-table";
import Tooltip from "@material-ui/core/Tooltip";


class DeviceTreatments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      totalResults: 0,
      totalPages: 0,
      pageSize: 10,
      currentPage: 1,
      loading: false,
      data: [],
      treatment_detail: {}
    }
    this._is_mounted = false
  }

  componentDidMount() {
    this._is_mounted = true
    const {device_serial} = this.props
    this.refresh(device_serial)
  }

  componentWillUnmount() {
    this._is_mounted = false
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.device_serial !== this.props.device_serial) {
      this.refresh(this.props.device_serial)
    }
  }

  sync_treatments() {
    const {device_serial} = this.props
    deviceService.syncTreatments(device_serial, res => {
      if (res.success) {
        this.refresh(device_serial)
      }
    })
  }

  download_treatment(treatment_id) {
    treatmentsService.download(treatment_id)
  }

  refresh(device_serial) {
    const {currentPage, pageSize} = this.state
    this.setState({loading: true})
    treatmentsService.getTreatments(
      undefined,
      undefined,
      device_serial,
      undefined,
      pageSize,
      currentPage - 1,
      result => {
        const total_pages = Math.ceil(result.total / pageSize)
        let newCurrentPage = currentPage
        if (currentPage > total_pages && result.total > 0) {
          newCurrentPage = total_pages
        }

        result.results.forEach((treatment) => {
        })

        this.setState({
          totalResults: result.total,
          currentPage: newCurrentPage,
          data: result.results,
          totalPages: total_pages,
          loading: false,
        })
      }, error => {
        this.setState({
          data: [],
          totalPages: 0,
          loading: false
        })
      });
  }

  render() {
    const {device_serial, classes, is_live, device_name, maps} = this.props
    const {currentPage, data, pageSize, loading, totalResults, totalPages} = this.state

    let filler_size = (currentPage - 1) * pageSize
    if (filler_size < 0) {
      filler_size = 0
    }
    let filler_array = Array(filler_size).keys()

    let columns = [
      {
        Header: <b>Treatment Information</b>,
        columns:
          [

            {
              Header: "Start Time", accessor: "start_time",
              Cell: row => {
                if (row.value === null) {
                  let date = new Date(row.original.creation_time)

                  let new_time = date.getTime() + (row.original.wait_time * 1000)
                  return <i>{new Date(new_time).toLocaleString("nl-BE")} (estimated)</i>
                }
                let date = new Date(row.value)
                return date.toLocaleString("nl-BE")
              },
              maxWidth: 170
            },
            {
              Header: "Map", accessor: "settings.site_id",
              maxWidth: 80,
              Cell: row => {
                return <Tooltip
                  id="tooltip-top"
                  title={maps[row.value] ? maps[row.value]["name"] : row.value}
                  placement="top"
                  classes={{tooltip: classes.tooltip}}
                >
                 <span>{row.value}</span>
                </Tooltip>
              },
            },
            {
              Header: "Mission", accessor: "settings.mission_id",
              maxWidth: 150
            },
            {
              Header: "Result", accessor: "finish_status",
              Cell: row => {
                let value = row.value
                if (row.value === "fully_completed") {
                  value = <Button size={'sm'} justIcon round simple color="success"> <Check/> </Button>
                }
                if (row.value === "partially_completed") {
                  if (row.original.stats && row.original.stats.reached_end){
                    value =  <Button size={'sm'} justIcon round simple color="success"> <Check/> </Button>
                  } else {
                    value =  <Button size={'sm'} justIcon round simple color="warning"> <Check/> </Button>
                  }
                }
                if (row.value === "paused") {
                  value =  <Button size={'sm'} justIcon round simple color="warning"> <Check/> </Button>
                }
                if (row.value === "stopped") {
                  value =  <Button size={'sm'} justIcon round simple color="warning"> <Error/> </Button>
                }
                if (row.value === "failed") {
                  value =  <Button size={'sm'} justIcon round simple color="danger"> <Error/> </Button>
                }

                return <Tooltip
                  id="tooltip-top"
                  title={row.value}
                  placement="top"
                  classes={{tooltip: classes.tooltip}}
                >
                 <span>{value}</span>
                </Tooltip>
              },
              maxWidth: 80
            },
            {
              Header: "Rows", accessor: "stats",
              maxWidth: 170,
              Cell: row => {
                let stats = row.value

                if (!row.value) {
                  return <p>UNKNOWN</p>
                }

                return <p>
                  Total: {stats ? stats["total_treated"] : ""} <br/>
                  Not Treated: {stats ? stats["total_failed"] : ""}
                </p>
              }
            },
            {
              Header: "No Recovery?",
              accessor: "events",
              minWidth: 280,
              Cell: row => {
                let no_recovery_reason = ""

                if (row.value === "" || row.value === undefined || row.value === null) {
                  return no_recovery_reason
                }

                row.value.forEach((t_event) => {
                  if (t_event["event_name"] === "fail") {
                    no_recovery_reason = t_event["data"]["no_recovery_reason"]
                  }
                })

                if (no_recovery_reason === "" || no_recovery_reason === null || no_recovery_reason === undefined) {
                  return ""
                }

                return <div>{no_recovery_reason.split("; ").map((rr, index) => {
                  let rrs = rr.split(": ")
                  return <p>{index + 1} - <b>{rrs[0]}:</b> {rrs[1]}</p>
                })}</div>
              }
            },
          ]
      },
      {
        Header: <b>Stats</b>,
        columns: [
          {
            Header: "Coverage",
            accessor: "stats",
            Cell: row => {
              let stats = row.value

              if (row.value === undefined || row.value === null) {
                return <p>UNKNOWN</p>
              }

              return Math.round(stats["coverage"] * 100, 2) + " %"
            }
          },
          {
            Header: "Reached End",
            accessor: "stats",
            Cell: row => {
              let stats = row.value

              if (stats === undefined || stats === null) {
                return <p>UNKNOWN</p>
              }

              return stats["reached_end"] ? "Yes" : "No"
            },
          },
        ],
        maxWidth: 200
      },
      {
        Header: <b>Actions</b>,
        columns: [
          {
            Header: "",
            accessor: "id",
            Cell: row => {
              return <div>
                <Link to={`/treatments/detail?treatment_id=${row.value}`}><HelpOutlineTwoTone/></Link>
                <Button simple justIcon round color={"primary"}
                        onClick={this.download_treatment.bind(this, row.value)}><Download/></Button>
              </div>
            },
            filterable: false,
            sortable: false,
            maxWidth: 100
          }
        ],
      }
    ]

    return (<GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <DeviceSelector disable_key_bindings={true}/>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader><h4 style={{color: primaryColor}}><b>Treatments - {device_name}</b></h4></CardHeader>
          <CardBody>
            <GridItem xs={12}>
              <h5># Results: {totalResults}</h5>
              <Button color="primary" onClick={this.refresh.bind(this, device_serial)}> Refresh
                Data </Button>
              <Button color="primary" onClick={this.sync_treatments.bind(this, device_serial)}
                      disabled={!is_live}> Sync Treatments </Button>
              <br/>
              <br/>
              {!is_live && <Alert severity={"warning"}>Device is not online, these may not be the last
                treatments</Alert>}
              <br/>
            </GridItem>
            <ReactTable
              columns={columns}
              data={[
                ...filler_array,
                ...data
              ]}
              sortable={false}
              filterable={false}
              loading={loading}
              pages={totalPages}
              page={currentPage - 1}
              pageSize={pageSize}
              onPageChange={(pageIndex) => this.setState({currentPage: pageIndex + 1}, () => this.refresh(device_serial))}
              onPageSizeChange={(newPageSize) => {
                this.setState({currentPage: 1, pageSize: newPageSize}, () => this.refresh(device_serial))
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>)
  }
}

function mapStateToProps(state) {
  const {device_serial} = state.device_fleet
  const {maps} = state.location
  let is_live = false
  if (state.device_fleet && state.device_fleet.device_detail && state.device_fleet.device_detail.state) {
    is_live = state.device_fleet.device_detail.state.is_live
  }


  let device_name = ""
  if (state.device_fleet && state.device_fleet.device_detail && state.device_fleet.device_detail.state) {
    device_name = state.device_fleet.device_detail.state.name
  }

  return {
    device_serial, is_live, device_name, maps
  }
}

export default withStyles(userProfileStyles)(connect(mapStateToProps)(DeviceTreatments))