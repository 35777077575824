import React from "react"
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import extendedTablesStyle from "./extendedTablesStyle.jsx"

import {primaryColor, warningColor} from "../../assets/jss/material-dashboard-pro-react";
import {deploymentService, mapService} from "../../_services";
import ReactFlow from 'react-flow-renderer';
import {CheckTwoTone, WarningTwoTone} from "@material-ui/icons";


class OrganisationDiagram extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      organisation: {},
      locations: {}
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  componentDidMount() {
    this._is_mounted = true
    this.refresh()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.organisation_id !== this.props.organisation_id) {
      this.refresh()
    }
  }

  refresh() {
    const {organisation_id} = this.props
    mapService.getOrganisation(organisation_id, res => {
      this.setState({organisation: res.result})

      if (res.result.locations) {
        res.result.locations.forEach((location) => {
          mapService.getGreenhousesForLocation(location["id"], loc_res => {
            this.setState(prevState => {
              return {
                ...prevState,
                locations: {
                  ...prevState.locations,
                  [location["id"]]: {
                    ...prevState.locations[location["id"]],
                    greenhouses: loc_res.greenhouses
                  }
                }
              }
            })
          })

          deploymentService.getDeploymentsForLocation(location["id"], 200, 0, dep_res => {
            this.setState(prevState => {
              return {
                ...prevState,
                locations: {
                  ...prevState.locations,
                  [location["id"]]: {
                    ...prevState.locations[location["id"]],
                    deployments: dep_res.results
                  }
                }
              }
            })
          })
        })
      }
    })
  }

  render() {
    const {all_devices} = this.props
    const {organisation, locations} = this.state

    let styles = {
      organisation_style: {
        backgroundColor: 'rgba(72,72,72,0.25)'
      },
      location_style: {
        backgroundColor: 'rgba(72,72,72,0.25)'
      },
      greenhouse_style: {
        backgroundColor: 'rgba(72,72,72,0.25)'
      },
      device_style: {
        backgroundColor: 'rgba(72,72,72,0.25)'
      }
    }

    let synced = <CheckTwoTone style={{paddingTop: "5px", color: primaryColor}}/>
    let not_synced = <WarningTwoTone style={{paddingTop: "5px", color: warningColor}}/>

    let nodes = []
    let edges = []

    nodes.push({
      id: "organisation_type",
      data: {
        label: <div>Organisation:<br/>...</div>
      },
      type: "input",
      draggable: false,
      position: {
        x: 0, y: 0
      },
      style: {
        ...styles["organisation_style"],
        width: 150,
      }
    })

    nodes.push({
      id: "location_type",
      data: {
        label: <div>Locations:<br/>...</div>
      },
      type: "input",
      draggable: false,
      position: {
        x: 0, y: 160
      },
      style: {
        ...styles["location_style"],
        width: 150,
      }
    })

    nodes.push({
      id: "greenhouse_type",
      data: {
        label: <div>Greenhouses:<br/>...</div>
      },
      type: "output",
      draggable: false,
      position: {
        x: 0, y: 310
      },
      style: {
        ...styles["greenhouse_style"],
        width: 150,
      }
    })

    nodes.push({
      id: "device_type",
      data: {
        label: <div>Devices:<br/>...</div>
      },
      type: "output",
      draggable: false,
      position: {
        x: 0, y: 550
      },
      style: {
        ...styles["device_style"],
        width: 150,
      }
    })

    nodes.push({
      id: organisation["id"],
      data: {
        label:
          <div>{organisation["name"]}<br/>{"..."}
          </div>
      },
      type: "input",
      draggable: false,
      position: {
        x: 200, y: 0
      },
      style: {
        width: 150,
      }
    })

    let min_x_dep = 200

    if (organisation["locations"]) {
      let org_start_width = 0
      organisation["locations"].forEach((location, idx) => {
        let min_width = 170
        if (locations[location["id"]]) {
          let greenhouses = locations[location["id"]]["greenhouses"]
          if (greenhouses && greenhouses.length > 0) {
            min_width = (greenhouses.length * 160) + 10
          }
        }

        nodes.push(
          {
            id: `parent-${location["id"]}`,
            draggable: false,
            position: {x: 200 + org_start_width, y: 150},
            style: {
              width: min_width,
              height: 245,
              backgroundColor: 'rgba(240,240,240,0.3)'
            },
          }
        )

        org_start_width += (min_width + 10)

        nodes.push({
          id: location["id"],
          data: {
            label:
              <div>{location["name"]}<br/>{"..."}
              </div>
          },
          type: "input",
          draggable: false,
          position: {
            x: (min_width / 2) - (150 / 2), y: 10
          },
          parentNode: `parent-${location["id"]}`,
          extent: 'parent',
        })
        edges.push({
          id: `${organisation["id"]}-${location["id"]}`,
          source: organisation["id"],
          target: `parent-${location["id"]}`
        })

        if (locations[location["id"]]) {
          let greenhouses = locations[location["id"]]["greenhouses"]
          if (greenhouses) {
            greenhouses.forEach((greenhouse, gh_idx) => {
              nodes.push({
                id: greenhouse["id"],
                data: {
                  label: <div>{greenhouse["name"]}<br/>{"..."}</div>
                },
                type: "output",
                draggable: false,
                position: {
                  x: (gh_idx * 150) + ((gh_idx + 1) * 10), y: 160
                },
                parentNode: `parent-${location["id"]}`,
                extent: 'parent',
              })
              edges.push({
                id: `${location["id"]}-${greenhouse["id"]}`,
                source: `${location["id"]}`,
                target: greenhouse["id"]
              })
            })
          }

          let deployments = locations[location["id"]]["deployments"]
          if (deployments) {
            deployments.forEach((deployment, gh_idx) => {
              let deployed_device = {}
              all_devices.forEach((device) => {
                if (device["serial"] === deployment["device_serial"]) {
                  deployed_device = device
                }
              })

              nodes.push({
                id: deployment["id"],
                data: {
                  label:
                    <div>{deployed_device["name"] ? deployed_device["name"] : deployment["device_serial"]}<br/>{"..."}
                    </div>
                },
                type: "output",
                draggable: false,
                position: {
                  x: min_x_dep, y: 550
                },
                style: {
                  width: 200,
                }

              })
              min_x_dep += 210
              edges.push({
                id: `${location["id"]}-${deployment["id"]}`,
                source: `parent-${location["id"]}`,
                target: deployment["id"]
              })
            })
          }
        }
      })
    }

    return (
      <div style={{minHeight: "750px", height: "750px", width: "100%"}}>
        Synced: {synced} - Not Synced: {not_synced} <br/><br/>
        <ReactFlow defaultNodes={nodes} defaultEdges={edges}/>
      </div>
    )
  }
}


function mapStateToProps(state) {
  const {all_devices} = state.device_fleet
  return {
    all_devices
  }
}


export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(OrganisationDiagram))
