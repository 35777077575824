import React from "react"
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Button from "components/CustomButtons"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

import extendedTablesStyle from "./extendedTablesStyle.jsx"

import {managementService, treatmentsService} from "_services"

import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import {CloseTwoTone, DoneTwoTone, HelpOutlineTwoTone, ReplayTwoTone} from "@material-ui/icons";
import {Link} from "react-router-dom";
import ReactTable from "../../components/react-table";
import Check from "@material-ui/icons/CheckCircleTwoTone";
import Error from "@material-ui/icons/ErrorTwoTone";
import Download from "@material-ui/icons/CloudDownloadTwoTone";
import MyDropdown from "../../components/MyDropdown";


class Treatments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      totalResults: 0,
      totalPages: 0,
      pageSize: 10,
      currentPage: 1,
      loading: false,
      updated_properties: {},
      data: [],
      all_life_cycle_stages_names: {}
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  componentDidMount() {
    this._is_mounted = true
    this.refresh()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  download_treatment(treatment_id) {
    treatmentsService.download(treatment_id)
  }

  update_treatment(treatment_id, test_mission) {
    treatmentsService.updateTreatment(treatment_id, test_mission, res => {
      this.refresh()
    })
  }

  delete_stats(treatment_id) {
    treatmentsService.deleteStats(treatment_id)
    this.refresh()
  }

  refresh() {
    const {currentPage, pageSize, device_serial} = this.state
    this.setState({loading: true})

    managementService.getAllLifeCycleStages(res => {
      let all_life_cycle_stages_names = {}

      res.result.forEach((life_cycle_stage) => {
        all_life_cycle_stages_names[life_cycle_stage.id] = life_cycle_stage.name
      })

      this.setState({all_life_cycle_stages_names})
    })
    treatmentsService.getTreatments(
      undefined,
      undefined,
      device_serial,
      undefined,
      pageSize,
      currentPage - 1,
      result => {
        const total_pages = Math.ceil(result.total / pageSize)
        let newCurrentPage = currentPage
        if (currentPage > total_pages && result.total > 0) {
          newCurrentPage = total_pages
        }

        result.results.forEach((treatment) => {
        })

        this.setState({
          totalResults: result.total,
          currentPage: newCurrentPage,
          data: result.results,
          totalPages: total_pages,
          loading: false,
        })
      }, error => {
        this.setState({
          data: [],
          totalPages: 0,
          loading: false
        })
      });
  }

  handle_form_input_selector(name, value) {
    this.setState({[name]: value}, () => this.refresh())
  }

  render() {
    const {currentPage, data, pageSize, loading, totalResults, totalPages, all_life_cycle_stages_names} = this.state
    const {all_devices} = this.props

    let filler_size = (currentPage - 1) * pageSize
    if (filler_size < 0) {
      filler_size = 0
    }
    let filler_array = Array(filler_size).keys()

    let columns = [
      {
        Header: "Start Time", accessor: "start_time",
        Cell: row => {
          if (row.value === null) {
            let date = new Date(row.original.creation_time)

            let new_time = date.getTime() + (row.original.wait_time * 1000)
            return <i>{new Date(new_time).toLocaleString("nl-BE")} (estimated)</i>
          }
          let date = new Date(row.value)
          return date.toLocaleString("nl-BE")
        },
      },
      {
        Header: "Map", accessor: "settings.site_id",
      },
      {
        Header: <p>
          Device
          {
            this.state["device_serial"] &&
            <Button size={'sm'} onClick={() => {
              this.setState({device_serial: undefined}, () => this.refresh())
            }} justIcon round simple color="danger"> <CloseTwoTone/> </Button>
          }
        </p>, accessor: "machine_serial",
        Cell: row => {
          let device_name = row.value
          if (all_devices) {
            all_devices.forEach((device) => {
              if (device["serial"] === row.value) {
                device_name = device["name"]
              }
            })
          }

          return device_name
        },
        filterable: true,
        Filter: ({filter, onChange}) =>
          <MyDropdown
            input_label={"Device Serial"}
            name={"device_serial"}
            value={this.state["device_serial"]}
            default_value={this.state["device_serial"]}
            options={[...all_devices.map((item) => {
              return {id: item.serial, value: item.name}
            })]}
            handle_selection={this.handle_form_input_selector.bind(this)}
          />
      },
      {
        Header: "Life Cycle", accessor: "device_life_cycle_stage",
        Cell: row => {
          return all_life_cycle_stages_names[row.value]
        }
      },
      {
        Header: "Mission", accessor: "finish_status",
        Cell: row => {
          let button = `(${row.value})`
          if (row.value === "fully_completed") {
            button = <Button size={'sm'} justIcon round simple color="success"> <Check/> </Button>
          }
          if (row.value === "partially_completed") {
            button = <Button size={'sm'} justIcon round simple color="warning"> <Check/> </Button>
          }
          if (row.value === "stopped") {
            button = <Button size={'sm'} justIcon round simple color="warning"> <Error/> </Button>
          }
          if (row.value === "paused") {
            button = <Button size={'sm'} justIcon round simple color="warning"> <Error/> </Button>
          }
          if (row.value === "failed") {
            button = <Button size={'sm'} justIcon round simple color="danger"> <Error/> </Button>
          }
          return <p> {button} {row.original.settings.mission_id} </p>
        },
        filterable: false
      },
      {
        Header: "Coverage",
        accessor: "stats",
        Cell: row => {
          let stats = row.value

          if (row.value === undefined || row.value === null) {
            return <p>UNKNOWN</p>
          }

          return <span style={{color: stats["accurately_calculated"] ? "rgb(100,169,68)" : "rgb(231,92,92)"}}>
                        {
                          stats["accurately_calculated"] ? <DoneTwoTone/> : <CloseTwoTone/>
                        }
            {Math.round(stats["coverage"] * 100, 2) + " %"}
            {!stats["accurately_calculated"] && <Button simple justIcon round color={"warning"}
                                                        onClick={this.delete_stats.bind(this, row.original.id)}><ReplayTwoTone/></Button>}
                    </span>
        }
      },
      {
        Header: "Test Mission?",
        accessor: "settings.test_mission",
        Cell: row => {
          let is_test_mission = row.value

          return <p>{is_test_mission ? "Yes" : "No"} <Button simple round color={"primary"}
                                                             onClick={this.update_treatment.bind(this, row.original.id, !row.value)}>{is_test_mission ? "Set as operational" : "Set as test"}</Button>
          </p>
        },
        filterable: false,
        sortable: false,
      },
      {
        Header: "",
        accessor: "id",
        Cell: row => {
          return <div>
            <Link to={`/treatments/detail?treatment_id=${row.value}`}><HelpOutlineTwoTone/></Link>
            <Button simple justIcon round color={"primary"}
                    onClick={this.download_treatment.bind(this, row.value)}><Download/></Button>
          </div>
        },
        filterable: false,
        sortable: false,
        maxWidth: 150
      }
    ]

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem xs={10}>
                  <h4 style={{color: primaryColor}}><b>{"Overview All Treatments"}</b></h4>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <h5># Results: {totalResults}</h5>
              <ReactTable
                columns={columns}
                data={[
                  ...filler_array,
                  ...data
                ]}
                sortable={false}
                filterable={false}
                loading={loading}
                pages={totalPages}
                page={currentPage - 1}
                pageSize={pageSize}
                onPageChange={(pageIndex) => this.setState({currentPage: pageIndex + 1}, () => this.refresh())}
                onPageSizeChange={(newPageSize) => {
                  this.setState({currentPage: 1, pageSize: newPageSize}, () => this.refresh())
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}


function mapStateToProps(state) {
  const {all_devices} = state.device_fleet
  const {user} = state.authentication
  return {
    user, all_devices
  }
}

export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(Treatments))