import buttonGroupStyle from "assets/jss/material-dashboard-pro-react/buttonGroupStyle.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import {
  cardTitle,
  dangerBtn,
  dangerColor,
  defaultFont,
  primaryBtn,
  successBtn,
  tooltip
} from "assets/jss/material-dashboard-pro-react.jsx";
import buttonStyle from "components/CustomButtons/buttonStyle.jsx";

const extendedTablesStyle = theme => ({
  ...customCheckboxRadioSwitch,
  ...buttonGroupStyle,
  ...buttonStyle,
  ...customSelectStyle,
  successBtn,
  dangerBtn,
  primaryBtn,
  tooltip,
  right: {
    textAlign: "right"
  },
  center: {
    textAlign: "center"
  },
  description: {
    maxWidth: "150px"
  },
  headertable: {
    textAlign: "left !important"
  },
  actionButton: {
    margin: "0 0 0 5px",
    padding: "5px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px"
    }
  },
  icon: {
    verticalAlign: "middle",
    width: "17px",
    height: "17px",
    top: "-1px",
    position: "relative"
  },
  imgContainer: {
    width: "120px",
    maxHeight: "160px",
    overflow: "hidden",
    display: "block"
  },
  img: {
    width: "100%",
    height: "auto",
    verticalAlign: "middle",
    border: "0"
  },
  tdName: {
    minWidth: "200px",
    fontWeight: "400",
    fontSize: "1.5em"
  },
  tdNameAnchor: {
    color: "#3C4858"
  },
  tdNameSmall: {
    color: "#999999",
    fontSize: "0.75em",
    fontWeight: "300"
  },
  tdNumber: {
    textAlign: "right",
    minWidth: "145px",
    fontWeight: "300",
    fontSize: "1.3em !important"
  },
  tdNumberSmall: {
    marginRight: "3px"
  },
  tdNumberAndButtonGroup: {
    lineHeight: "1 !important"
  },
  positionAbsolute: {
    position: "absolute",
    right: "0",
    top: "0"
  },
  customFont: {
    fontSize: "16px !important"
  },
  actionButtonRound: {
    width: "auto",
    height: "auto",
    minWidth: "auto"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  TypeTag: {
    zIndex: "4",
    [theme.breakpoints.up("md")]: {
      background: 'rgba(40,100,80,0.5)',
      color: "#FFFFFF",
      minWidth: "16px",
      height: "16px",
      borderRadius: "10px",
      textAlign: "center",
      lineHeight: "14px",
      padding: '5px 15px 5px 15px',
    },
    [theme.breakpoints.down("sm")]: {
      ...defaultFont,
      fontSize: "14px",
      marginRight: "8px"
    }
  },
  LocationTag: {
    zIndex: "4",
    [theme.breakpoints.up("md")]: {
      background: 'rgba(3,130,130,0.5)',
      color: "#FFFFFF",
      minWidth: "16px",
      height: "16px",
      borderRadius: "10px",
      textAlign: "center",
      lineHeight: "14px",
      padding: '5px 15px 5px 15px',
    },
    [theme.breakpoints.down("sm")]: {
      ...defaultFont,
      fontSize: "14px",
      marginRight: "8px"
    }
  },
  OtherTag: {
    zIndex: "4",
    [theme.breakpoints.up("md")]: {
      background: 'rgba(130,130,80,0.5)',
      color: "#FFFFFF",
      minWidth: "16px",
      height: "16px",
      borderRadius: "10px",
      textAlign: "center",
      lineHeight: "14px",
      padding: '5px 15px 5px 15px',
    },
    [theme.breakpoints.down("sm")]: {
      ...defaultFont,
      fontSize: "14px",
      marginRight: "8px"
    }
  },
  removeTagButton: {
    [theme.breakpoints.up("md")]: {
      color: "#FFFFFF",
    },
    [theme.breakpoints.down("sm")]: {
      color: dangerColor,
    }
  },
  containerTags: {
    overflow: 'hidden'
  }
});

export default extendedTablesStyle;
