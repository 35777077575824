import {deploymentService, mapService} from "../../_services";
import {locationActions} from "../../_actions";
import {store} from '_helpers/store'


function get_organisations_page(page, accumulator, cb) {
  mapService.getOrganisations(10, page, res => {
    accumulator.total = res["total"]
    accumulator.items = [...accumulator.items, ...res["results"]]
    if (accumulator.items.length === accumulator.total) return cb(accumulator);

    return get_organisations_page(page + 1, accumulator, cb)
  })
}

export function update_organisation_list() {
  get_organisations_page(0, {"items": []}, res => {
    store.dispatch(locationActions.updateOrganisations(res.total, res.items))

  })
}


function get_maps_page(page, accumulator, cb) {
  mapService.getAllMaps(10, page, res => {
    accumulator.total = res["total"]
    accumulator.items = [...accumulator.items, ...res["results"]]
    if (accumulator.items.length === accumulator.total) return cb(accumulator);

    return get_maps_page(page + 1, accumulator, cb)
  })
}

export function update_map_list() {
  get_maps_page(0, {"items": []}, res => {
    store.dispatch(locationActions.updateMaps(res.total, res.items))
  })
}

function get_locations_page(page, accumulator, cb) {
  mapService.getLocations(50, page, res => {
    accumulator.total = res["total"]
    accumulator.items = [...accumulator.items, ...res["results"]]
    if (accumulator.items.length === accumulator.total) return cb(accumulator);

    return get_locations_page(page + 1, accumulator, cb)
  })
}

export function update_location_list() {
  get_locations_page(0, {"items": []}, res => {
    store.dispatch(locationActions.updateLocations(res.total, res.items))
  })
}

function get_greenhouses_page(page, accumulator, cb) {
  mapService.getGreenhouses(50, page, res => {
    accumulator.total = res["total"]
    accumulator.items = [...accumulator.items, ...res["results"]]
    if (accumulator.items.length === accumulator.total) return cb(accumulator);

    return get_greenhouses_page(page + 1, accumulator, cb)
  })
}

export function update_greenhouse_list() {
  get_greenhouses_page(0, {"items": []}, res => {
    store.dispatch(locationActions.updateGreenhouses(res.total, res.items))
  })
}

function get_deployments_page(page, accumulator, cb) {
  deploymentService.getDeployments(50, page, res => {
    accumulator.total = res["total"]
    accumulator.items = [...accumulator.items, ...res["results"]]
    if (accumulator.items.length === accumulator.total) return cb(accumulator);

    return get_deployments_page(page + 1, accumulator, cb)
  })
}

export function update_deployment_list() {
  get_deployments_page(0, {"items": []}, res => {
    store.dispatch(locationActions.updateDeployments(res.total, res.items))
  })
}