import SuperAgent from 'superagent'
import {api_root} from 'index.js'
import {errorHandling, unauthorizedRedirect} from './error.handler.js'
import {getKeycloak} from 'react-router-keycloak';

export const treatmentsService = {
  getTreatments,
  deleteStats,
  getEventAnalysis,
  getTreatmentsLastDay,
  getTreatmentDetail,
  download,
  updateTreatment,
};


function deleteStats(treatment_id, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/treatment/" + treatment_id + "/recalculate_stats")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getTreatments(from, to, machine_serial, map_id, per_page, page, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/treatment/all")
  .query({from})
  .query({to})
  .query({machine_serial})
  .query({map_id})
  .query({page})
  .query({per_page})
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getEventAnalysis(treatment_id, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/treatment/" + treatment_id + "/event_analysis")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getTreatmentDetail(treatment_id, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/treatment/" + treatment_id + "/detail")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function download(treatment_id, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/treatment/" + treatment_id + "/download")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const FileSaver = require('file-saver');
    const text = response.text;
    const data = text && JSON.parse(text);
    let blob = new Blob([JSON.stringify(data["result"], null, 4)], {type: "text/plain;charset=utf-8"});
    FileSaver.saveAs(blob, treatment_id + ".json")
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function updateTreatment(treatment_id, test_mission, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .patch(api_root + "/treatment/" + treatment_id + "/update")
  .send({test_mission})
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getTreatmentsLastDay(callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/treatment/last_day")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}