import {primaryBoxShadow, primaryColor} from "assets/jss/material-dashboard-pro-react";

const myDropdownStyle = {
  select: {
    whiteSpace: "normal",
        maxWidth: "70vw"
    },
    dropdownFormControl: {
        margin: '10px 1px 10px 0px !important',
        padding: '0 0 10px 0',
        overflow: "hidden",
        // bottom-border line under entire control
        '& .MuiInput-underline:before': {
            borderBottomColor: '#d2d2d2',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: primaryColor
        }
    },
    dropdownSelectField: {
        // styling label above text field
        whiteSpace: "normal",
        maxWidth: "80vw",
        '& .MuiInputLabel-root': {
            fontSize: 'small',
            textTransform: 'uppercase',
            color: '#3c4858 !important'
        }
    },
    dropdownEndAdornment: {
        // don't let 'unit' adornment overlap the 'open select' arrow icon
        marginRight: '24px',
        color: '#555' // color is copied from loginPageStyle.jsx
    },
    dropdownPaper: {
        maxHeight: '260px !important',
        padding: '5px',
        '& .MuiList-padding': {
            padding: '0'
        }
    },
    dropdownMenuItem: {
        // min-height is 'auto' for screens wider than 600px
        overflow: "hidden",
        minHeight: '48px',
        padding: '10px 20px !important',
        borderRadius: '2px !important',
        transition: 'all 150ms linear',
        fontSize: 'small !important',
        fontWeight: '400',
        whiteSpace: 'normal !important',
        color: '#333 !important',
        '&:hover': {
            backgroundColor: primaryColor + ' !important',
            color: '#fff !important',
            ...primaryBoxShadow
        },
        '&.Mui-selected': {
            backgroundColor: 'transparent !important',
            color: '#333',
            '&:hover': {
                backgroundColor: primaryColor + ' !important',
                color: '#fff',
                '&:after': {
                    color: '#fff'
                }
            },
            '&:after': {
                // draw checkmark
                top: '16px',
                right: '12px',
                width: '12px',
                height: '5px',
                borderLeft: '2px solid currentColor',
                transform: 'rotate(-45deg)',
                opacity: '1',
                color: '#3c4858',
                position: 'absolute',
                content: "''",
                borderBottom: '2px solid currentColor',
                transition: 'opacity 90ms cubic-bezier(0,0,.2,.1)'
            }
        }
    }
}

export default myDropdownStyle;