import SuperAgent from 'superagent'
import {api_root} from 'index.js'
import {errorHandling, unauthorizedRedirect} from './error.handler.js'
import {getKeycloak} from "react-router-keycloak";


export const systemService = {
  getCommissionedDevices,
  getStateOverview,
  getMetrics,
  getStateOnline,
};


function getStateOverview(callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/system/state/overview")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function getCommissionedDevices(callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/system/machines")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function getStateOnline(callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/system/machines/online")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function getMetrics(callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/system/metrics")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}
