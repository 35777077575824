import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"

// Assets
import userProfileStyles from "./userProfileStyles.jsx"

// Services
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import Table from "../../components/Table";
import {get_battery_icon, get_connection_icon, time_to_h_and_min} from "../helper_functions/device_icons";
import ReactTimeAgo from "react-time-ago";
import {beautify_name} from "../helpers";
import {connect} from "react-redux";
import {deviceService} from "../../_services";
import {CloseTwoTone, DoneTwoTone} from "@material-ui/icons";
import GreenhouseMap from "../Maps/GreenhouseMap";
import Tooltip from "@material-ui/core/Tooltip";
import {Icon} from "leaflet";


class LumionFixDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error_overview: [],
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  componentDidMount() {
    this._is_mounted = true
    const {device_serial} = this.props
    this.refresh(device_serial)
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.device_serial !== this.props.device_serial) {
      this.refresh(this.props.device_serial)
    }
  }

  refresh(device_serial) {
    deviceService.getErrorOverview(device_serial, 48, res => {
      this.setState({error_overview: res.result})
    })
  }

  render() {
    const {device_serial, classes, device_detail, device_name} = this.props
    const {error_overview} = this.state

    let state_detail = device_detail["state"]

    if (state_detail === undefined || state_detail === null) {
      state_detail = {}
    }

    let current_location_data = [["", ""]]

    if (state_detail !== {} && state_detail["position"]) {
      current_location_data = []
      current_location_data.push([<b>Current node</b>, state_detail["position"]["value"]["node_id"]])
      current_location_data.push([<b>Current X</b>, state_detail["position"]["value"]["x"]])
      current_location_data.push([<b>Current Y</b>, state_detail["position"]["value"]["y"]])
      current_location_data.push([<b>Current theta</b>, state_detail["position"]["value"]["theta"]])
      current_location_data.push([<b>Current velocity</b>, state_detail["velocity"]["value"]])
      current_location_data.push(["", ""])
    }

    let treatment_data = []

    if (state_detail !== {}) {
      if (state_detail["active_treatment"]) {
        treatment_data.push([<b>Treatment
          ID</b>, state_detail["active_treatment"]["value"] ? state_detail["active_treatment"]["value"]["treatment_id"] : ""])
        treatment_data.push([<b>Mission
          Name</b>, state_detail["active_treatment"]["value"] ? state_detail["active_treatment"]["value"]["mission_name"] : ""])
        if (state_detail["active_treatment"]["value"] && state_detail["active_treatment"]["value"]["wait_time"]) {
          treatment_data.push([<b>Starts in</b>,
            state_detail["active_treatment"]["value"]["wait_time"] > 0 ?
            <div>
            <ReactTimeAgo future={true}
                          date={new Date().getTime() + (state_detail["active_treatment"]["value"]["wait_time"] * 1000)}
                          locale="en-UK"/> -
            ({time_to_h_and_min(state_detail["active_treatment"]["value"]["wait_time"])})
          </div>:
              "In progress"
          ])
        }
        if (state_detail["active_treatment"]["value"] && state_detail["active_treatment"]["value"]["time_left_seconds"]) {
          treatment_data.push([<b>Ready In</b>, <div>
            <ReactTimeAgo future={true}
                          date={new Date().getTime() + (state_detail["active_treatment"]["value"]["time_left_seconds"] * 1000)}
                          locale="en-UK"/> -
            ({time_to_h_and_min(state_detail["active_treatment"]["value"]["time_left_seconds"])})
          </div>])
        }
        if (state_detail["active_treatment"]["value"]) {
          let start_time = state_detail["active_treatment"]["value"]["start_time"]
          let creation_time = state_detail["active_treatment"]["value"]["creation_time"]
          let wait_time = state_detail["active_treatment"]["value"]["wait_time"]
          let table_value = ""
          if (start_time === null) {
            let date = new Date(creation_time)

            let new_time = date.getTime() + (wait_time * 1000)
            table_value =  <i>{new Date(new_time).toLocaleString("nl-BE")} (estimated)</i>
          } else {
            let date = new Date(start_time)
            table_value = date.toLocaleString("nl-BE")
          }
          treatment_data.push([<b>Start Time</b>, table_value])
        }
        treatment_data.push(["", ""])
      } else {
        treatment_data.push(["There is no active treatment"])
        treatment_data.push([""])
      }
    }

    let sw_version = []

    if (state_detail !== {}) {
      if ("software_version" in state_detail) {
        sw_version.push([<b>Software Version</b>, state_detail["software_version"]["value"]])
      }
      if ("software_version_hash" in state_detail) {
        sw_version.push([<b>Software Hash</b>, state_detail["software_version_hash"]["value"]])
      }
      if ("software_version_status" in state_detail) {
        let status = state_detail["software_version_status"]["value"]
        let manually_started = state_detail["software_manually_started"]["value"]
        if (status === "True") {
          status = "Software Version is valid"
        }
        if (status === "False") {
          status = "Software on NUC and RPI is different"
        }
        if (status === "MissingExpected") {
          status = "The expected software version is missing on RPI or on the NUC"
        }
        if (status === "PI_error") {
          status = "There is an error with the software version on RPI"
        }
        if (status === "NUC_error") {
          status = "There is an error with the software version on RPI"
        }
        if (manually_started === true) {
          status = "Nuc and/or RPi have been started manually"
        }

        sw_version.push([<b>Software Status</b>, status])
      }
      if ("software_version_status" in state_detail) {
        let manually_started = state_detail["software_manually_started"]["value"]

        if (manually_started === true) {
          sw_version.push([<b>Warning:</b>, "Nuc and/or RPi have been started manually"])
        }
      }
      sw_version.push(["", ""])
    }

    let sim_info = []

    if (state_detail !== {}) {
      if ("sim_info" in state_detail) {
        if (state_detail["sim_info"]["value"]) {
          sim_info.push([<b>Customer</b>, state_detail["sim_info"]["value"]["customer"]])
          sim_info.push([<b>Rate Plan</b>, state_detail["sim_info"]["value"]["rate_plan"]])
          sim_info.push([<b>Status</b>, state_detail["sim_info"]["value"]["status"]])
          sim_info.push([<b>CTD Usage</b>, state_detail["sim_info"]["value"]["ctd_data_usage"]])
          sim_info.push([<b>Limit Reached</b>, state_detail["sim_info"]["value"]["overage_limit_reached"] ? "Yes" : "No"])
          sim_info.push([<b>Limit Override</b>, state_detail["sim_info"]["value"]["overage_limit_override"]])
        }
      }
      sim_info.push(["", ""])
    }

    let maps = [["", "", <b>S</b>, <b>M</b>, <b>CO</b>, <b>G</b>, <b>O</b>, <b>SE</b>, <b>TO</b>, <b>UV</b>,
      <b>VE</b>, "Valid?"]]

    if (state_detail !== {}) {
      if ("site_id" in state_detail && state_detail["site_version"] && state_detail["site_version"]["value"]) {
        let [base, obs, miss, gutt, uv, sen, to, con, ve] = state_detail["site_version"]["value"].split("#")
        maps.push([<b>Current
          Site:</b>, state_detail["site_id"]["value"], base, miss, con, gutt, obs, sen, to, uv, ve, ""])
      }

      if ("available_maps" in state_detail && state_detail["available_maps"] && state_detail["available_maps"]["value"]) {
        state_detail["available_maps"]["value"].sort((a, b) => {
          return a["map_id"] < b["map_id"] ? -1 : 1
        }).forEach((available_map, index) => {
          let title = <div
            style={{fontSize: "0.9rem"}}
          >
            <ul
              style={{fontSize: "0.9rem"}}
            >
              {Object.keys(available_map["errors"]).map((error_key) => {
                return <li>
                  <b>{error_key}</b>: {available_map["errors"][error_key]}
                </li>
              })
              }
            </ul>
          </div>

          let [base, obs, miss, gutt, uv, sen, to, con, ve] = available_map["map_version"].split("#")

          maps.push(
            [
              index === 0 ? <b>Other Sites:</b> : "",
              available_map["map_name"] + " (" + available_map["map_id"] + ")",
              base, miss, con, gutt, obs, sen, to, uv, ve,
              <Tooltip
                id="tooltip2"
                title={available_map["valid"] ? "Correct Map" : title}
                placement="right"
                classes={{tooltip: classes.tooltip}}
                style={{fontSize: "0.9rem"}}
              >
                                <span
                                  style={{color: available_map["valid"] ? "rgb(100,169,68)" : "rgb(231,92,92)"}}>{available_map["valid"] ?
                                  <DoneTwoTone/> : <CloseTwoTone/>}</span>
              </Tooltip>


            ])
        })
      }
      maps.push(["", "", "", "", "", "", "", "", "", "", "", ""])
    }

    const red_icon = new Icon({
      iconUrl: require('assets/icons/Fix.svg'),
      iconSize: [50, 50],
      iconAnchor: [25, 25],
      popupAnchor: [0, -20]
    });

    return (
      <GridContainer>
        <GridItem xs={12} md={6}>
          <h5 style={{color: primaryColor}}>General</h5>
          <Table
            color={"primary"}
            tableData={[
              [
                <b>Serial Number</b>,
                device_serial
              ],
              [
                <b>Name</b>,
                <div>
                  {state_detail["is_live"] && state_detail["has_wifi"] && get_connection_icon(classes, state_detail["is_live"]["value"], state_detail["last_seen"], state_detail["has_wifi"]["value"])}
                  &nbsp; &nbsp;
                  {state_detail["name"]}
                </div>
              ],
              [
                <b>Last Connection</b>,
                state_detail["last_seen"] ? (
                  <span>
                                        {new Date(Date.parse(state_detail["last_seen"])).toLocaleString('nl-BE')}
                    &nbsp;&nbsp;(&nbsp;<ReactTimeAgo date={state_detail["last_seen"]}
                                                     locale="en-UK"/>&nbsp;)
                                        </span>
                ) : "Never connected"
              ],
              ["", ""]
            ]}/>
        </GridItem>
        <GridItem xs={12} md={6}>
          <h5 style={{color: primaryColor}}>Maps</h5>
          <Table
            color={"primary"}
            tableData={maps}/>
        </GridItem>
        <GridItem xs={12} md={4}>
          <h5 style={{color: primaryColor}}>State</h5>
          <Table
            color={"primary"}
            tableData={[
              [
                <b>State</b>,
                state_detail["state"] && state_detail["state"]["value"] ? (
                  state_detail["state"]["value"].toLowerCase() === 'error' ?
                    <span
                      style={{color: 'rgb(255,0,0)'}}>{beautify_name(state_detail["state"]["value"])} - {state_detail["error_code"]["value"]}</span>
                    :
                    <p>{beautify_name(state_detail["state"]["value"])}</p>
                ) : ""
              ],
              [
                <b>Battery Level</b>,
                state_detail["battery_level"] && state_detail["battery_level"]["value"] ? get_battery_icon(state_detail["battery_level"]["value"]) : ""
              ],
              ["", ""]
            ]}/>
        </GridItem>
        <GridItem xs={12} md={4}>
          <h5 style={{color: primaryColor}}>Software Version</h5>
          <Table
            color={"primary"}
            tableData={sw_version}/>
        </GridItem>
        <GridItem xs={12} md={4}>
          <h5 style={{color: primaryColor}}>Sim Information</h5>
          <Table
            color={"primary"}
            tableData={sim_info}/>
        </GridItem>
        <GridItem xs={12} md={6}>
          <h5 style={{color: primaryColor}}>Error States Last 48h</h5>
          <Table
            color={"primary"}
            tableData={[
              [<b>Start Time</b>, <b>Stop Time</b>, <b>Duration</b>, <b>Next State</b>],
              ...error_overview.map((error_overview) => {
                return [
                  new Date(error_overview["start_time"]).toLocaleString('nl-BE'),
                  new Date(error_overview["stop_time"]).toLocaleString('nl-BE'),
                  time_to_h_and_min(error_overview["duration_seconds"]),
                  error_overview["next_state"]
                ]
              }),
              ["", "", "", ""],
            ]}/>
        </GridItem>
        <GridItem xs={12} md={6}>
          <h5 style={{color: primaryColor}}>Current Treatment</h5>
          <Table
            color={"primary"}
            tableData={treatment_data}/>
        </GridItem>
        <GridItem xs={12} md={12}>
          <h5 style={{color: primaryColor}}>Current Location</h5>
          {state_detail["site_id"] && state_detail["site_id"]["value"] && state_detail["site_version"] && state_detail["site_version"]["value"] && state_detail["position"] &&
            <GreenhouseMap
              map_id={state_detail["site_id"]["value"]}
              map_version={state_detail["site_version"]["value"]}
              markers={[{
                "key": "current_position",
                "popup": device_name,
                "x": state_detail["position"] ? state_detail["position"]["value"]["x"] : 0,
                "y": state_detail["position"] ? state_detail["position"]["value"]["y"] : 0,
                "icon": red_icon,
              }]
              }
              alternate={
                <Table
                  color={"primary"}
                  tableData={current_location_data}/>
              }
            />
          }
        </GridItem>
      </GridContainer>
    )
  }
}

function mapStateToProps(state) {
  const {device_detail, device_serial} = state.device_fleet


  let device_name = ""
  if (state.device_fleet && state.device_fleet.device_detail && state.device_fleet.device_detail.state) {
    device_name = state.device_fleet.device_detail.state.name
  }
  return {
    device_detail, device_serial, device_name
  }
}


export default withStyles(userProfileStyles)(connect(mapStateToProps)(LumionFixDetail))
