import React from "react"
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

import extendedTablesStyle from "./extendedTablesStyle.jsx"

import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";

import Table from "../../components/Table";
import {incidentService} from "../../_services";
import ReactTimeAgo from "react-time-ago";
import DeviceDropdown from "../helper_functions/DeviceDropdown";


class IncidentAnalytics extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      "per_error_code": {
        "calculated_at": undefined,
        "per_error_code": {},
        "total_number_of_incidents": 0
      },
      "per_device": {
        "calculated_at": undefined,
        "per_error_code": {},
        "total_number_of_incidents": 0
      }
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  componentDidMount() {
    this._is_mounted = true

    incidentService.getPerErrorCode(res => {
      this.setState({"per_error_code": res.result})
    })
    this.refresh(this.props.device_serial)
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.device_serial !== this.props.device_serial) {
      this.refresh(this.props.device_serial)
    }
  }

  refresh(device_serial) {
    incidentService.getPerDeviceSerial(device_serial, res => {
      this.setState({"per_device": res.result})
    })
  }

  get_per_error_code() {
    const {per_error_code} = this.state

    if (per_error_code === undefined || per_error_code === null)
      return {}

    if (per_error_code["per_error_code"] === undefined || per_error_code["per_error_code"] === null)
      return {}

    return per_error_code["per_error_code"]
  }

  get_incidents_per_device() {
    const {per_device} = this.state

    if (per_device === undefined || per_device === null)
      return {}

    if (per_device["per_error_code"] === undefined || per_device["per_error_code"] === null)
      return {}

    return per_device["per_error_code"]
  }

  render() {
    const {error_codes, device_detail} = this.props
    let incidents_per_error_codes = this.get_per_error_code()
    let last_updated_per_error_codes = this.state.per_error_code["calculated_at"]
    let total_nr_per_error_codes = this.state.per_error_code["total_number_of_incidents"]


    let incidents_per_device_serial = this.get_incidents_per_device()
    let last_updated_device_serial = this.state.per_device["calculated_at"]
    let total_nr_incidents_device_serial = this.state.per_device["total_number_of_incidents"]

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader><h4 style={{color: primaryColor}}><b>{"Most occurring error codes"}</b>
                <br/>
                {last_updated_per_error_codes !== undefined ?
                  <p>Calculated at: <ReactTimeAgo date={last_updated_per_error_codes} locale="en-UK"/>
                  </p> : ""}
              </h4></CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="primary"
                  style={{fontSize: '10px'}}
                  tableData={[
                    [
                      <b>Error Code</b>,
                      <b>Description</b>,
                      <b>Occurrences</b>,
                    ],
                    ...Object.keys(incidents_per_error_codes)
                    .sort((a, b) => {
                      if (total_nr_per_error_codes === 0) {
                        return -1
                      }
                      let occurrences_a = incidents_per_error_codes[a].length / total_nr_per_error_codes
                      let occurrences_b = incidents_per_error_codes[b].length / total_nr_per_error_codes

                      return occurrences_b - occurrences_a
                    })
                    .map((error_code) => {
                      let occurrences = "-"
                      if (total_nr_per_error_codes > 0) {
                        occurrences = incidents_per_error_codes[error_code].length / total_nr_per_error_codes
                        occurrences = Math.round((occurrences + Number.EPSILON) * 100)
                      }
                      return [
                        error_code,
                        error_codes[error_code] ? error_codes[error_code]["development"] : "Unknown",
                        `${occurrences}% (${incidents_per_error_codes[error_code].length}/${total_nr_per_error_codes})`
                      ]
                    }),
                    ["", "", ""]
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>


          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader><h4 style={{color: primaryColor}}>
                <b>{"Most occurring error codes for "} {device_detail["name"]}</b>
                <br/>
                {last_updated_device_serial !== undefined ?
                  <p>Calculated at: <ReactTimeAgo date={last_updated_device_serial} locale="en-UK"/>
                  </p> : ""}
              </h4></CardHeader>
              <CardBody>
                <DeviceDropdown/>
                <Table
                  tableHeaderColor="primary"
                  style={{fontSize: '10px'}}
                  tableData={[
                    [
                      <b>Error Code</b>,
                      <b>Description</b>,
                      <b>Occurrences</b>,
                    ],
                    ...Object.keys(incidents_per_device_serial)
                    .sort((a, b) => {
                      if (total_nr_incidents_device_serial === 0) {
                        return -1
                      }
                      let occurrences_a = incidents_per_device_serial[a].length / total_nr_incidents_device_serial
                      let occurrences_b = incidents_per_device_serial[b].length / total_nr_incidents_device_serial

                      return occurrences_b - occurrences_a
                    })
                    .map((error_code) => {
                      let occurrences = "-"
                      if (total_nr_incidents_device_serial > 0) {
                        occurrences = incidents_per_device_serial[error_code].length / total_nr_incidents_device_serial
                        occurrences = Math.round((occurrences + Number.EPSILON) * 100)
                      }
                      return [
                        error_code,
                        error_codes[error_code] ? error_codes[error_code]["development"] : "Unknown",
                        `${occurrences}% (${incidents_per_device_serial[error_code].length}/${total_nr_incidents_device_serial})`
                      ]
                    }),
                    ["", "", ""]
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {device_serial, error_codes, device_detail} = state.device_fleet
  return {
    device_serial, error_codes, device_detail
  }
}

export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(IncidentAnalytics))
