import {sweetAlertConstants} from '_constants'

export const sweetAlertActions = {
  success,
  danger,
  warning,
  info,
  clean,
  clear
};

function success(options) {
  return {type: sweetAlertConstants.SUCCESS, options}
}

function danger(options) {
  return {type: sweetAlertConstants.DANGER, options}
}

function warning(options) {
  return {type: sweetAlertConstants.WARNING, options}
}

function info(options) {
  return {type: sweetAlertConstants.INFO, options}
}

function clean(options) {
  return {type: sweetAlertConstants.CLEAN, options}
}

function clear() {
  return {type: sweetAlertConstants.CLEAR}
}
