import {deviceFleetConstants} from '_constants'

const initialState = {
  online_devices: {},
  device_detail: {
    device_serial: undefined,
    in_control: {
      in_control: false,
      controller: null
    },
    state: {
      is_live: false
    }
  },
  all_devices: [],
  all_commissioned_devices: [],
  error_codes: {}
};

export function device_fleet(state = initialState, action) {
  switch (action.type) {
    case deviceFleetConstants.UPDATE_ONLINE_DEVICES:
      return {
        ...state,
        online_devices: action.online_devices,
      };
    case deviceFleetConstants.UPDATE_ALL_DEVICES:
      return {
        ...state,
        all_devices: action.all_devices,
      };
    case deviceFleetConstants.UPDATE_ALL_COMMISSIONED_DEVICES:
      return {
        ...state,
        all_commissioned_devices: action.all_commissioned_devices,
      };
    case deviceFleetConstants.UPDATE_DEVICE_SERIAL:
      return {
        ...state,
        device_serial: action.device_serial,
      };
    case deviceFleetConstants.UPDATE_DEVICE_DETAIL:
      return {
        ...state,
        device_detail: {
          ...state.device_detail,
          ...action.device_detail
        }
      };
    case deviceFleetConstants.UPDATE_ERROR_CODES:
      return {
        ...state,
        error_codes: {
          ...state.error_codes,
          ...action.error_codes
        }
      };
    default:
      return state
  }
}
