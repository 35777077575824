import React from "react"
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import Tooltip from "@material-ui/core/Tooltip"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Button from "components/CustomButtons"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

import extendedTablesStyle from "./extendedTablesStyle.jsx"

import {treatmentsService} from "_services"

import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import MyReactTable from "../../components/MyReactTable/MyReactTable";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "../../components/CustomInput";
import Check from "@material-ui/icons/CheckCircleTwoTone";
import Error from "@material-ui/icons/ErrorTwoTone";
import Info from "@material-ui/icons/InfoTwoTone";
import Table from "../../components/Table";


class TreatmentAnalysis extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      events: [],
      start_time: "",
      finish_time: "",
      state_page: 0,
      loading: false,
      treatment_id: null
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  on_page_change(new_page) {
    this.setState({state_page: new_page})
  }

  componentDidMount() {
    this._is_mounted = true
    this.setState({loading: true})
    this.refresh()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  refresh() {
    const {treatment_id} = this.state
    treatmentsService.getEventAnalysis(treatment_id, res => {
      this.setState({
        events: res.result,
        start_time: res.start_time,
        finish_time: res.finish_time,
        loading: false
      })
    })
  }

  change_value(config_name, value) {
    this.setState({[config_name]: value})
  }


  getErrorCodeFromData(data) {
    let error_code = undefined
    if (data !== null && data !== undefined) {
      if (data.startsWith("0x") && data !== "0x00000000") {
        error_code = data
      }
    }
    return error_code
  }

  render() {
    const {classes, error_codes} = this.props
    const {
      events,
      start_time,
      finish_time,
      state_page,
      loading,
      treatment_id
    } = this.state

    let columns = [
      {
        Header: "Time",
        accessor: "time",
        Cell: row => {
          return new Date(row.value).toLocaleString("nl-BE")
        },
        maxWidth: 225
      },

      {
        Header: "Level",
        accessor: "level",
        Cell: row => {
          if (row.value === "success") {
            return <Button size={'sm'} justIcon round simple color="success">
              <Check/> </Button>
          }
          if (row.value === "info") {
            return <Button size={'sm'} justIcon round simple color="info">
              <Info/> </Button>
          }
          if (row.value === "warning") {
            return <Button size={'sm'} justIcon round simple color="warning">
              <Error/> </Button>
          }
          if (row.value === "error") {
            return <Button size={'sm'} justIcon round simple color="danger">
              <Error/> </Button>
          }
          return row.value
        },

        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }

          return row.level === filter.value;

        },
        Filter: ({filter, onChange}) =>
          <FormControl fullWidth className={classes.selectFormControl}>
            <Select
              onChange={event => onChange(event.target.value)}
              style={{width: "100%"}}
              value={filter ? filter.value : "all"}
            >
              <MenuItem classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }} value={'all'}>
                {"all"}
              </MenuItem>
              <MenuItem classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }} value={'success'}>
                <Button size={'sm'} justIcon round simple color="success">
                  <Check/> </Button>
              </MenuItem>
              <MenuItem classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }} value={'info'}>
                <Button size={'sm'} justIcon round simple color="info">
                  <Info/> </Button>
              </MenuItem>
              <MenuItem classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }} value={'warning'}>
                <Button size={'sm'} justIcon round simple color="warning">
                  <Error/> </Button>
              </MenuItem>
              <MenuItem classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }} value={'error'}>
                <Button size={'sm'} justIcon round simple color="danger">
                  <Error/> </Button>
              </MenuItem>
            </Select>
          </FormControl>,
        maxWidth: 70
      },
      {
        Header: "Device",
        accessor: "robot",
      },
      {
        Header: "User",
        accessor: "user",
      },
      {
        Header: "Data",
        accessor: "data",
        maxWidth: 200,
        Cell: row => {
          let error_code = this.getErrorCodeFromData(row.value)
          let content = row.value
          if (error_code !== undefined) {
            content = <span
              style={{color: 'rgb(255,0,0)'}}>
                             <Tooltip
                               id="tooltip2"
                               title={error_codes[error_code] ? <span
                                 style={{fontSize: "1.1rem"}}>{error_codes[error_code]["development"]}</span> : "Unknown"}
                               placement="right"
                               classes={{tooltip: classes.tooltip}}
                             >
                                 <div>{error_code} <Info fontSize={"small"}/></div>
                             </Tooltip>
                        </span>
          }
          return content
        }
      }
    ]

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem xs={4}>
                  <h4 style={{color: primaryColor}}>
                    <b>{"Event analysis"}</b>
                  </h4>
                </GridItem>
                <GridItem xs={8} style={{textAlign: "right"}}>
                  <CustomInput
                    labelText={"Treatment ID"}
                    inputProps={{
                      onChange: event => this.change_value("treatment_id", event.target.value),
                      type: "text",
                      value: treatment_id
                    }}
                  />
                  &nbsp;&nbsp;
                  <Button color={"primary"} onClick={() => this.refresh()}>Analyse</Button>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <div>
                <Table
                  tableData={
                    [
                      [<b>Treatment ID</b>, treatment_id],
                      [<b>Showing events from</b>, new Date(start_time).toLocaleString("nl-BE")],
                      [<b>Showing events to</b>, new Date(finish_time).toLocaleString("nl-BE")],
                      ["", ""],
                    ]
                  }
                />
              </div>
              <MyReactTable
                id={"event_analysis"}
                data={events}
                filterable
                columns={columns}
                getTrProps={(state, rowInfo, column) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      style: {
                        background: rowInfo.row._original.level === "error" ? "rgba(255, 0, 0, 0.05)" : null
                      }
                    }
                  } else {
                    return {}
                  }
                }}
                defaultPageSize={10}
                page={state_page}
                onPageChange={this.on_page_change.bind(this)}
                showPaginationTop={false}
                showPaginationBottom={true}
                className=" -highlight"
                loading={loading}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}


function mapStateToProps(state) {
  const {user} = state.authentication
  const {error_codes} = state.device_fleet
  return {
    user, error_codes
  }
}


export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(TreatmentAnalysis))
