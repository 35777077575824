import React from 'react'
import ReactDOM from 'react-dom'
import {Route, Router, Switch} from 'react-router-dom'
import {CookiesProvider} from 'react-cookie'
import {Provider} from 'react-redux'
import {createBrowserHistory} from 'history'
import {store} from '_helpers/store'
import WelcomePage from 'routes/WelcomePage'
import KeycloakProvider, {configureKeycloak, Login, Logout, PrivateRoute} from 'react-router-keycloak';
import Dashboard from "routes/Dashboard.jsx";

import 'assets/scss/material-dashboard-pro-react.css?v=1.4.0'
import "react-vis/dist/style.css"

import registerServiceWorker from './registerServiceWorker'
import {userConstants} from "./_constants";

import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en.json'
import nl from 'javascript-time-ago/locale/nl.json'

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(nl)

export const history = createBrowserHistory();

export var api_root = '/api';
if (process.env.NODE_ENV === "development") {
  api_root = 'http://' + window.location.hostname + ':5001/api';
}

const KEYCLOAK_URL = "https://login.octin.io";
const KEYCLOAK_REALM = window.KEYCLOAK_REALM;
const KEYCLOAK_CLIENT_ID = "robot-dashboard-frontend";


function handleRefresh(token) {
  console.log('Called every time the token is refreshed so you can update it locally', token);
}

// Initialize a keycloak instance that will be used in every subcomponent
configureKeycloak(KEYCLOAK_URL, KEYCLOAK_REALM, KEYCLOAK_CLIENT_ID);


ReactDOM.render(
  <KeycloakProvider loginPath="/login" logoutPath="/log-out" onRefresh={handleRefresh}>
    <CookiesProvider>
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={WelcomePage}/>
            <Route path="/login" render={props => <Login redirectTo="/dashboard" {...props} />}/>
            <Route path="/log-out" render={props => <Logout redirectTo="/" {...props} />}/>
            <PrivateRoute exact component={Dashboard}/>
          </Switch>
        </Router>
      </Provider>
    </CookiesProvider>
  </KeycloakProvider>,
  document.getElementById('root')
);

registerServiceWorker();