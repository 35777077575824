import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// Assets
import userProfileStyles from "./userProfileStyles.jsx"
import CardHeader from "../../components/CardHeader";
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import GridContainer from "../../components/GridContainer";
import GridItem from "../../components/GridItem";
import Card from "../../components/Card";
import CardBody from "../../components/CardBody";
import Table from "../../components/Table";
import CustomInput from "../../components/CustomInput";
import Button from "../../components/CustomButtons";
import {managementService} from "../../_services";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {connect} from "react-redux";
import {sweetAlertActions} from "../../_actions";
import {update_device_lists} from "../helper_functions/device_icons";
import MyDropdown from "../../components/MyDropdown";


class AddDevice extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      device_serial: "",
      name: "",
      device_type: "LUMION_FIX",
      all_device_types: [],
      is_creating: false
    }
    this._is_mounted = false
    this.timer = null
  }

  componentDidMount() {
    this._is_mounted = true
    managementService.getAllDeviceTypes(res => {
      this.setState({all_device_types: res.result})
    })
  }

  componentWillUnmount() {
    this._is_mounted = false
    clearTimeout(this.timer)
    this.timer = null
  }

  change(event, stateName) {
    this.setState({
      [stateName]: event.target.value
    })
  }

  create_device() {
    const {device_serial, name, device_type} = this.state
    const {dispatch} = this.props
    this.setState({is_creating: true})
    managementService.createDevice(device_serial, name, device_type, res => {
      if (res.result) {
        update_device_lists()
        dispatch(sweetAlertActions.success({title: "The device is created"}))
        this.setState({device_serial: "", name: ""})
      } else {
        dispatch(sweetAlertActions.danger("Something went wrong while creating the device"))
      }
      this.setState({is_creating: false})
    })
  }

  start_create_device() {
    const {dispatch} = this.props
    const {device_serial, name, device_type} = this.state

    if (device_serial === "") {
      dispatch(sweetAlertActions.danger({
        title: "Error",
        content: <div>
          <h5>You cannot add a device without a serial number</h5>
        </div>
      }))
    } else {
      dispatch(sweetAlertActions.clean({
        title: "Are you sure?",
        showCancel: true,
        onCancel: this.clear_popup.bind(this),
        cancelBtnText: "No",
        onConfirm: this.create_device.bind(this),
        confirmBtnText: "Yes",
        content: <div>
          <h5>You will add a device with next information</h5>
          <p><b>Device Serial:</b> {device_serial}</p>
          <p><b>Name:</b> {name}</p>
          <p><b>Device Type:</b> {device_type}</p>
          <br/>
          <p>Are you sure all information is correct?</p>
          <p>A device cannot be removed from the system once it has been created.</p>
        </div>
      }))
    }
  }

  clear_popup() {
    const {dispatch} = this.props;
    dispatch(sweetAlertActions.clear())
  }

  handleDeviceType(name, value){
    this.setState({
      "device_type": value,
    })
  }

  render() {
    const {all_device_types, is_creating} = this.state

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader><h4 style={{color: primaryColor}}><b>Add Device</b></h4></CardHeader>
            <CardBody>
              <Table
                color={"primary"}
                tableData={[[<p>Device Serial:</p>, <CustomInput
                  id={"device_serial"}
                  labelText={"Device Serial"}
                  inputProps={{
                    onChange: event => this.change(event, "device_serial"),
                    type: "text",
                    value: this.state["device_serial"]
                  }}
                />], [<p>Name:</p>, <CustomInput
                  id={"name"}
                  labelText={"Name"}
                  inputProps={{
                    onChange: event => this.change(event, "name"),
                    type: "text",
                    value: this.state["name"]
                  }}
                />, ''], [<p>Device Type:</p>,
                  <MyDropdown
                    input_label={"Choose Device Type"}
                    name={"device_type"}
                    value={this.state["device_type"]}
                    handle_selection={
                      this.handleDeviceType.bind(this)
                    }
                    options={[
                      ...all_device_types.map(function (item, key) {
                        return {
                          id: item.id,
                          value: item.name
                        }
                      })
                    ]}
                  />, ''], [<p>By clicking create you will create a new device, this
                  cannot be undone</p>, <Button disabled={is_creating} color={"primary"}
                                                onClick={this.start_create_device.bind(this)}>
                  Create
                </Button>, ''], ["", "", '']]}/>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>)
  }
}


export default withStyles(userProfileStyles)(connect(null)(AddDevice))