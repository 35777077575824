import {combineReducers} from 'redux'

import {alert} from './alert.reducer'
import {sweet_alert} from './sweetalert.reducer'
import {authentication} from './authentication.reducer'
import {device_fleet} from './device_fleet.reducer'
import {location} from './location.reducer'

export default combineReducers({
  alert,
  sweet_alert,
  authentication,
  device_fleet,
  location
})
