import {store} from "../../_helpers/store";

export function sortObj(list, key) {
  function compare(a, b) {
    a = a[key]
    b = b[key]
    let type = (typeof (a) === 'string' ||
      typeof (b) === 'string') ? 'string' : 'number'
    let result
    if (type === 'string') result = a.localeCompare(b)
    else result = a - b
    return result
  }

  return list.sort(compare)
}


export function get_sorted_devices() {
  const {all_commissioned_devices} = store.getState().device_fleet
  return all_commissioned_devices.sort((a, b) => {
    return a["name"] < b["name"] ? -1 : 1
  })
}