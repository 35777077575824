import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import userProfileStyles from "../../views/Profile/userProfileStyles";


import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Button from "components/CustomButtons";


class Pagination extends React.Component {
  static propTypes = {
    pages: PropTypes.number,
    page: PropTypes.number,
    onPageChange: PropTypes.func,
    previousText: PropTypes.string,
    nextText: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.changePage = this.changePage.bind(this);

    this.state = {
      visiblePages: this.getVisiblePages(null, props.pages)
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.pages !== nextProps.pages) {
      this.setState({
        visiblePages: this.getVisiblePages(null, nextProps.pages)
      });
    }

    this.changePage(nextProps.page + 1);
  }

  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(page => page <= totalPages);
  };

  getVisiblePages = (page, total) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  changePage(page) {
    const activePage = this.props.page + 1;

    if (page === activePage) {
      return;
    }

    const visiblePages = this.getVisiblePages(page, this.props.pages);

    this.setState({
      visiblePages: this.filterPages(visiblePages, this.props.pages)
    });

    this.props.onPageChange(page - 1);
  }

  render() {
    const {pages} = this.props;
    const {visiblePages} = this.state;
    const activePage = this.props.page + 1;

    return (

      <GridContainer>
        <GridItem xs={4}>
          <Button
            fullWidth
            color={"transparent"}
            onClick={
              () => {
                if (activePage === 1) return;
                this.changePage(activePage - 1)
              }
            }
            disabled={activePage === 1}
          >
            Previous Button
          </Button>
        </GridItem>
        <GridItem xs={4} style={{textAlign: "center"}}>
          {visiblePages.map((page, index, array) => {
            return <Button
              justIcon
              key={page}
              color={activePage === page ? "primary" : "black"}
              onClick={this.changePage.bind(null, page)}
            >
              {array[index - 1] + 2 < page ? `...${page}` : page}
            </Button>
          })}
        </GridItem>
        <GridItem xs={4} style={{textAlign: "right"}}>
          <Button
            fullWidth
            color={"transparent"}
            onClick={
              () => {
                if (activePage === pages) return;
                this.changePage(activePage + 1)
              }
            }
            disabled={activePage === pages}
          >
            Next Button
          </Button>
        </GridItem>
      </GridContainer>
    );
  }
}


export default withStyles(userProfileStyles)(Pagination)