import SuperAgent from 'superagent'
import {api_root} from 'index.js'
import {errorHandling, unauthorizedRedirect} from './error.handler.js'
import {getKeycloak} from 'react-router-keycloak';

export const deploymentService = {
  getDeploymentActions,
  getDeployments,
  getDeploymentById,
  closeDeployment,
  getDeploymentsForLocation,
};


function getDeployments(per_page, page, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/deployment/all")
  .query({per_page})
  .query({page})
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function getDeploymentActions(deployment_id, action_type, per_page, page, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/deployment/actions")
  .query({deployment_id})
  .query({action_type})
  .query({per_page})
  .query({page})
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function getDeploymentsForLocation(location_id, per_page, page, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/deployment/location/" + location_id)
  .query({per_page})
  .query({page})
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getDeploymentById(deployment_id, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/deployment/" + deployment_id)
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function closeDeployment(deployment_id, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/deployment/" + deployment_id + "/close")
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}
