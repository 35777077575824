import {history} from 'index.js'
import {alertActions} from '_actions'
import {store} from '_helpers/store'
import {getKeycloak} from 'react-router-keycloak';


// TODO: clean error handling

export var unauthorizedRedirect = function (req) {

  const keycloak = getKeycloak()
  req.on('response', function (res) {
    if (res.status === 401) {
      // if (res.body.error !== undefined && res.body.error.includes('ExpiredSignatureError')){
      // refreshToken(req)
      // } else {
      keycloak.logout()
      history.push("/");
      store.dispatch(alertActions.error(res.body.error))
      // }
    }
  })
};


export var errorHandling = function (req) {
  if (req.response === undefined) {
    return false
  } else if (req.response.statusCode === 400) {
    store.dispatch(alertActions.error(req.response.body.error.split(': ')[1]))
  } else if (req.response.statusCode === 409) {
    store.dispatch(alertActions.error(req.response.body.error.split(': ')[1]))
  } else if (req.response.statusCode === 404) {
    store.dispatch(alertActions.error(req.response.body.error.split(': ')[1]))
  } else if (req.response.body && req.response.body.error) {
    store.dispatch(alertActions.error(req.response.body.error))
  } else {
    store.dispatch(alertActions.error('Something went wrong'))
  }
};
