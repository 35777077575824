import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

// Assets
import userProfileStyles from "./userProfileStyles.jsx"

// Services
import {deviceService} from '_services'
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import {filterSubstring} from "../helper_functions/table_filtering";
import Button from "../../components/CustomButtons";
import Error from "@material-ui/icons/ErrorTwoTone";
import Info from "@material-ui/icons/InfoTwoTone";
import Check from "@material-ui/icons/CheckCircleTwoTone";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import MyReactTable from "../../components/MyReactTable/MyReactTable";
import Tooltip from "@material-ui/core/Tooltip";
import {connect} from "react-redux";
import DeviceSelector from "./DeviceSelector";
import {Link} from "react-router-dom";


class DeviceEvents extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      device_events: [],
      loading_events: false
    }
    this._is_mounted = false
  }

  componentDidMount() {
    this._is_mounted = true
    const {device_serial} = this.props
    this.refresh(device_serial)
  }

  componentWillUnmount() {
    this._is_mounted = false
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.device_serial !== this.props.device_serial) {
      this.refresh(this.props.device_serial)
    }
  }

  getErrorCodeFromData(data) {
    let error_code = undefined
    if (data !== null && data !== undefined) {
      if (data.startsWith("0x") && data !== "0x00000000") {
        error_code = data
      }
    }
    return error_code
  }

  refresh(device_serial) {
    this.setState({loading_events: true})
    deviceService.getDeviceEvents(device_serial, res => {
        let events = res.result
        this.setState({device_events: events, loading_events: false})
      },
      reject => {
        this.setState({loading_events: false})
      })
  }

  render() {
    const {device_serial, classes, error_codes, device_name} = this.props
    const {device_events, loading_events} = this.state

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <DeviceSelector disable_key_bindings={true}/>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader><h4 style={{color: primaryColor}}><b>Device Events - {device_name}</b></h4>
            </CardHeader>
            <CardBody>
              <Button color="primary" onClick={this.refresh.bind(this, device_serial)}>
                Refresh Data
              </Button>
              <MyReactTable
                id={"events"}
                data={device_events}
                filterable
                loading={loading_events}
                columns={[
                  {
                    Header: "Belgian Time",
                    accessor: "belgian_local_time",
                    filterMethod: (filter, row) => filterSubstring(filter, row),
                    filterable: false,
                    Cell: row => {
                      return new Date(row.value).toLocaleString("nl-BE")
                    },
                    maxWidth: 170
                  },
                  {
                    Header: "Device Local Time",
                    accessor: "device_local_time",
                    filterMethod: (filter, row) => filterSubstring(filter, row),
                    filterable: false,
                    maxWidth: 170,
                    Cell: row => {
                      if (row.original.device_local_timezone === null || row.value === "unknown") {
                        return "Unknown"
                      }
                      return new Date(row.value).toLocaleString("nl-BE", {timeZone: row.original.device_local_timezone})
                    },

                  },
                  {
                    Header: "Level",
                    accessor: "level",
                    Cell: row => {
                      if (row.value === "success") {
                        return <Button size={'sm'} justIcon round simple color="success">
                          <Check/> </Button>
                      }
                      if (row.value === "info") {
                        return <Button size={'sm'} justIcon round simple color="info">
                          <Info/> </Button>
                      }
                      if (row.value === "warning") {
                        return <Button size={'sm'} justIcon round simple color="warning">
                          <Error/> </Button>
                      }
                      if (row.value === "error") {
                        return <Button size={'sm'} justIcon round simple color="danger">
                          <Error/> </Button>
                      }
                      return row.value
                    },

                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }

                      return row.level === filter.value;

                    },
                    Filter: ({filter, onChange}) =>
                      <FormControl fullWidth className={classes.selectFormControl}>
                        <Select
                          onChange={event => onChange(event.target.value)}
                          style={{width: "100%"}}
                          value={filter ? filter.value : "all"}
                        >
                          <MenuItem classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }} value={'all'}>
                            {"all"}
                          </MenuItem>
                          <MenuItem classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }} value={'success'}>
                            <Button size={'sm'} justIcon round simple color="success">
                              <Check/> </Button>
                          </MenuItem>
                          <MenuItem classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }} value={'info'}>
                            <Button size={'sm'} justIcon round simple color="info">
                              <Info/> </Button>
                          </MenuItem>
                          <MenuItem classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }} value={'warning'}>
                            <Button size={'sm'} justIcon round simple color="warning">
                              <Error/> </Button>
                          </MenuItem>
                          <MenuItem classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }} value={'error'}>
                            <Button size={'sm'} justIcon round simple color="danger">
                              <Error/> </Button>
                          </MenuItem>
                        </Select>
                      </FormControl>,
                    maxWidth: 70
                  },
                  {
                    Header: "Message",
                    accessor: "message",
                    filterMethod: (filter, row) => filterSubstring(filter, row),
                  },
                  {
                    Header: "Data",
                    accessor: "data",
                    Cell: row => {
                      let error_code = this.getErrorCodeFromData(row.value)
                      let content = row.value
                      if (error_code !== undefined) {
                        content = <span
                          style={{color: 'rgb(255,0,0)'}}>
                                                     <Tooltip
                                                       id="tooltip2"
                                                       title={error_codes[error_code] ? <span
                                                         style={{fontSize: "1.1rem"}}>{error_codes[error_code]["development"]}</span> : "Unknown"}
                                                       placement="right"
                                                       classes={{tooltip: classes.tooltip}}
                                                     >
                                                         <div>{error_code} <Info fontSize={"small"}/></div>
                                                     </Tooltip>
                                                </span>
                      }
                      return content
                    },
                    maxWidth: 300,
                    filterMethod: (filter, row) => filterSubstring(filter, row),
                  },
                  {
                    Header: "Action",
                    accessor: "action",
                    Cell: row => {
                      if (row.value === "to_incident") {
                        return <Link to={`/incidents/all?incident_id=${row.original.data}`}>To
                          Incident</Link>
                      } else if (row.value === "to_treatment") {
                        return <Link
                          to={`/treatments/detail?treatment_id=${row.original.data}`}>To
                          Treatment</Link>
                      } else if (row.value === null) {
                        return "-"
                      } else {
                        return "Unknown action: " + row.value
                      }
                    },
                    maxWidth: 150,
                    filterMethod: (filter, row) => filterSubstring(filter, row),
                  },
                ]}
                getTrProps={(state, rowInfo, column) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      style: {
                        background: rowInfo.row.level === "fatal" ? "rgba(255, 0, 0, 0.1)" : null
                      }
                    }
                  } else {
                    return {}
                  }
                }}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className=" -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

function mapStateToProps(state) {
  const {device_serial, error_codes} = state.device_fleet

  let device_name = ""
  if (state.device_fleet && state.device_fleet.device_detail && state.device_fleet.device_detail.state) {
    device_name = state.device_fleet.device_detail.state.name
  }

  return {
    device_serial, error_codes, device_name
  }
}

export default withStyles(userProfileStyles)(connect(mapStateToProps)(DeviceEvents))
