export function sortRows(a, b, desc, test) {
  // force null and undefined to the bottom
  a = a === null || a === undefined ? '' : a;
  b = b === null || b === undefined ? '' : b;

  test = test.toLowerCase()
  // force any string values to lowercase
  a = typeof a === 'string' ? a.toLowerCase() : a;
  b = typeof b === 'string' ? b.toLowerCase() : b;
  // Return either 1 or -1 to indicate a sort priority
  if (a === test) {
    if (desc) {
      a = "zzzzzzzzzz";
      b = "a" + b;
    } else {
      a = "aaaaaaaaaaa";
      b = "z" + b;
    }
  } else if (b === test) {
    if (desc) {
      a = "a" + a;
      b = "zzzzzzzzzzz";
    } else {
      a = "z" + a;
      b = "aaaaaaaaaaa";
    }
  } else {
    a = "z" + a;
    b = "z" + b;
  }
  if (a > b) {
    return 1
  }
  if (a < b) {
    return -1
  }
  // returning 0, undefined or any falsey value will use subsequent sorts or
  // the index as a tiebreaker
  return 0
}


export function filterSubstring(filter, row, name_column) {
  const filter_name = name_column ? name_column : filter.id
  let element = row["_original"][filter_name] ? row["_original"][filter_name].toString() : '';
  if (filter_name.indexOf(".") !== -1 && row["_original"] !== undefined) {
    let filters = filter_name.split(".")
    element = row["_original"]
    filters.forEach((filter_part) => {
      if (typeof element === "object") {
        element = element[filter_part] ? element[filter_part] : ''
      }
    })
    element = element.toString()
  }

  return element.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1;
}

