import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import {NavLink} from "react-router-dom";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";

// core components
import HeaderLinks from "components/HeaderLinks";

import sidebarStyle from "./sidebarStyle.jsx";

import avatar from "assets/img/faces/avatar.jpg";

var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.

class SidebarWrapper extends React.Component {
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  render() {
    const {className, user, headerLinks, links} = this.props;
    return (
      <div className={className} ref="sidebarWrapper">
        {user}
        {headerLinks}
        {links}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      openDevice: this.activeRoute("/device"),
      openMaps: this.activeRoute("/maps"),
      openManagement: this.activeRoute("/management"),
      openNimbusManagement: this.activeRoute("/nimbus"),
      openFleet: this.activeRoute("/fleet"),
      openIncidents: this.activeRoute("/incidents"),
      openTreatments: this.activeRoute("/treatments"),
      miniActive: true
    };
    this.activeRoute.bind(this);
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1;
  }

  openCollapse(collapse) {
    var st = {};

    if (collapse === 'openAvatar') {
      st['openAvatar'] = !this.state['openAvatar'];
      st['openManagement'] = false
      st['openNimbusManagement'] = false
      st['openFleet'] = false
      st['openDevice'] = false
      st['openMaps'] = false
      st['openIncidents'] = false
      st['openTreatments'] = false
    }

    if (collapse === 'openManagement') {
      st['openManagement'] = !this.state['openManagement'];
      st['openAvatar'] = false
      st['openFleet'] = false
      st['openDevice'] = false
      st['openMaps'] = false
      st['openIncidents'] = false
      st['openTreatments'] = false
    }

    if (collapse === 'openNimbusManagement') {
      st['openNimbusManagement'] = !this.state['openNimbusManagement'];
      st['openManagement'] = false
      st['openAvatar'] = false
      st['openFleet'] = false
      st['openDevice'] = false
      st['openMaps'] = false
      st['openIncidents'] = false
      st['openTreatments'] = false
    }

    if (collapse === 'openFleet') {
      st['openFleet'] = !this.state['openFleet'];
      st['openAvatar'] = false
    }

    if (collapse === 'openDevice') {
      st['openDevice'] = !this.state['openDevice'];
      st['openAvatar'] = false
    }

    if (collapse === 'openMaps') {
      st['openMaps'] = !this.state['openMaps'];
      st['openAvatar'] = false
    }

    if (collapse === 'openIncidents') {
      st['openIncidents'] = !this.state['openIncidents'];
      st['openAvatar'] = false
    }

    if (collapse === 'openTreatments') {
      st['openTreatments'] = !this.state['openTreatments'];
      st['openAvatar'] = false
    }

    this.setState(st);
  }

  render() {
    const {classes, color, logo, logoMini, image, routes, bgColor} = this.props;
    const itemText = classes.itemText + " " + cx({[classes.itemTextMini]: this.props.miniActive && this.state.miniActive});
    const collapseItemText = classes.collapseItemText + " " + cx({[classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive});
    const userWrapperClass = classes.user + " " + cx({[classes.whiteAfter]: bgColor === "white"});
    const caret = classes.caret
    const collapseItemMini = classes.collapseItemMini
    const itemIcon = classes.itemIcon
    const logoClasses = classes.logo + " " + cx({[classes.whiteAfter]: bgColor === "white"});
    const drawerPaper = classes.drawerPaper + " " + cx({[classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,});
    const sidebarWrapper = classes.sidebarWrapper + " " + cx({
      [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
      [classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1
    });

    var storeUser = this.props.user

    var user = (
      <div className={userWrapperClass}>
        <div className={classes.photo}>
          <img src={avatar} className={classes.avatarImg} alt="..."/>
        </div>
        <List className={classes.list}>
          <ListItem className={classes.item + " " + classes.userItem}>
            <NavLink
              to={"#"}
              className={classes.itemLink + " " + classes.userCollapseButton}
              onClick={() => this.openCollapse("openAvatar")}
            >
              <ListItemText
                primary={(storeUser !== undefined ? storeUser["first_name"] + " " + storeUser["name"] : "")}
                secondary={<b
                  className={caret + " " + classes.userCaret + " " + (this.state.openAvatar ? classes.caretActive : "")}/>}
                disableTypography={true}
                className={itemText + " " + classes.userItemText}
              />
            </NavLink>
            <Collapse in={this.state.openAvatar} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                {routes.map((prop, key) => {
                  if (prop.profile !== true) {
                    return null;
                  }
                  if (prop.hidden === true) {
                    return null;
                  }
                  if (prop.collapse) {
                    const navLinkClasses = classes.itemLink + " " + cx({[" " + classes.collapseActive]: this.activeRoute(prop.path)});
                    return (
                      <ListItem key={key} className={classes.item}>
                        <NavLink
                          to={"#"}
                          className={navLinkClasses}
                          onClick={() => this.openCollapse(prop.state)}
                        >
                          <ListItemIcon className={itemIcon}>
                            {typeof prop.icon === "string" ? (<Icon>{prop.icon}</Icon>) : (
                              <prop.icon/>)}
                          </ListItemIcon>
                          <ListItemText
                            primary={prop.name}
                            secondary={<b
                              className={caret + " " + (this.state[prop.state] ? classes.caretActive : "")}/>}
                            disableTypography={true}
                            className={itemText}
                          />
                        </NavLink>
                        <Collapse in={this.state[prop.state]} unmountOnExit>
                          <List className={classes.list + " " + classes.collapseList}>
                            {prop.views.map((prop, key) => {
                              if (prop.redirect) {
                                return null;
                              }
                              const navLinkClasses = classes.collapseItemLink + " " + cx({[" " + classes[color]]: this.activeRoute(prop.path)});
                              return (
                                <ListItem key={key} className={classes.collapseItem}>
                                  <NavLink to={prop.path} className={navLinkClasses}>
                                    <span className={collapseItemMini}>
                                      <prop.icon/>
                                    </span>

                                    <ListItemText
                                      primary={prop.name}
                                      disableTypography={true}
                                      className={collapseItemText}
                                    />
                                  </NavLink>
                                </ListItem>
                              );
                            })}
                          </List>
                        </Collapse>
                      </ListItem>
                    );
                  }
                  const navLinkClasses = classes.itemLink + " " + cx({[" " + classes[color]]: this.activeRoute(prop.path)});
                  return (
                    <ListItem key={key} className={classes.item}>
                      <NavLink to={prop.path} className={navLinkClasses}>
                        <ListItemIcon className={itemIcon}>
                          {typeof prop.icon === "string" ? (<Icon>{prop.icon}</Icon>) : (
                            <prop.icon/>)}
                        </ListItemIcon>
                        <ListItemText
                          primary={prop.name}
                          disableTypography={true}
                          className={itemText}
                        />
                      </NavLink>
                    </ListItem>
                  );
                })}
              </List>
            </Collapse>
          </ListItem>
        </List>
      </div>
    );
    var links = (
      <List className={classes.list}>
        {routes.map((prop, key) => {
          if (prop.redirect) {
            return null
          }
          if (prop.profile) {
            return null
          }
          if (prop.hidden) {
            return null
          }
          if (prop.collapse) {
            const navLinkClasses = classes.itemLink + " " + cx({[" " + classes.collapseActive]: this.activeRoute(prop.path)});
            return (
              <ListItem key={key} className={classes.item}>
                <NavLink
                  to={"#"}
                  className={navLinkClasses}
                  onClick={() => this.openCollapse(prop.state)}
                >
                  <ListItemIcon className={itemIcon}>
                    {typeof prop.icon === "string" ? (<Icon>{prop.icon}</Icon>) : (<prop.icon/>)}
                  </ListItemIcon>
                  <ListItemText
                    primary={prop.name}
                    secondary={
                      <b
                        className={
                          caret +
                          " " +
                          (this.state[prop.state] ? classes.caretActive : "")
                        }
                      />
                    }
                    disableTypography={true}
                    className={itemText}
                  />
                </NavLink>
                <Collapse in={this.state[prop.state]} unmountOnExit>
                  <List className={classes.list + " " + classes.collapseList}>
                    {prop.views.map((prop, key) => {
                      if (prop.redirect) {
                        return null;
                      }
                      const navLinkClasses = classes.collapseItemLink + " " + cx({[" " + classes[color]]: this.activeRoute(prop.path)});
                      return (
                        <ListItem key={key} className={classes.collapseItem}>
                          <NavLink to={prop.path} className={navLinkClasses}>
                            <span className={collapseItemMini}>
                              <prop.icon/>
                            </span>

                            <ListItemText
                              primary={prop.name}
                              disableTypography={true}
                              className={collapseItemText}
                            />
                          </NavLink>
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </ListItem>
            );
          }
          const navLinkClasses = classes.itemLink + " " + cx({[" " + classes[color]]: this.activeRoute(prop.path)});
          return (
            <ListItem key={key} className={classes.item}>
              <NavLink to={prop.path} className={navLinkClasses}>
                <ListItemIcon className={itemIcon}>
                  {typeof prop.icon === "string" ? (<Icon>{prop.icon}</Icon>) : (<prop.icon/>)}
                </ListItemIcon>
                <ListItemText
                  primary={prop.name}
                  disableTypography={true}
                  className={itemText}
                />
              </NavLink>
            </ListItem>
          );
        })}
      </List>
    );

    var brandMini = (
      <div className={logoClasses}>
        <a className={classes.logoMini} href={"https://dashboard.octin.io"}>
          <img src={logoMini} alt="logo" className={classes.imgMini}/>
        </a>
      </div>
    );
    var brand = (
      <div className={logoClasses}>
        <a className={classes.logoMaxi} href={"https://dashboard.octin.io"}>
          <img src={logo} alt="logo" className={classes.img}/>
        </a>
      </div>
    );
    return (
      <div ref="mainPanel">
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={this.props.open}
            classes={{paper: drawerPaper + " " + classes[bgColor + "Background"]}}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{keepMounted: true /* Better open performance on mobile.*/}}
          >
            {this.props.miniActive ? brandMini : brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              headerLinks={<HeaderLinks/>}
              links={links}
            />
            {image !== undefined ? (<div className={classes.background}
                                         style={{backgroundImage: "url(" + image + ")"}}/>) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({miniActive: false})}
            onMouseOut={() => this.setState({miniActive: true})}
            anchor={"left"}
            variant="permanent"
            open
            classes={{paper: drawerPaper + " " + classes[bgColor + "Background"]}}
          >
            {this.props.miniActive ? brandMini : brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              links={links}
            />
            {image !== undefined ? (<div className={classes.background}
                                         style={{backgroundImage: "url(" + image + ")"}}/>) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "blue"
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "primary",
    "octinionLight",
  ]),
  logo: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object)
};
export default withStyles(sidebarStyle)(Sidebar)
