import {locationConstants} from '_constants'

export const locationActions = {
  updateOrganisations,
  updateMaps,
  updateGreenhouses,
  updateDeployments,
  updateLocations,
};

function updateOrganisations(total_nr_organisations, organisations) {
  return {type: locationConstants.UPDATE_ORGANISATIONS, total_nr_organisations, organisations}
}

function updateMaps(total_nr_maps, maps) {
  return {type: locationConstants.UPDATE_MAPS, total_nr_maps, maps}
}

function updateGreenhouses(total_nr_greenhouses, greenhouses) {
  return {type: locationConstants.UPDATE_GREENHOUSES, total_nr_greenhouses, greenhouses}
}

function updateLocations(total_nr_locations, locations) {
  return {type: locationConstants.UPDATE_LOCATIONS, total_nr_locations, locations}
}

function updateDeployments(total_nr_deployments, deployments) {
  return {type: locationConstants.UPDATE_DEPLOYMENTS, total_nr_deployments, deployments}
}
