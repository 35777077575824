import React from "react";
import Copy from "@material-ui/icons/FileCopyOutlined";
import Button from "../CustomButtons/Button"

function ClipboardButton({text}) {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Button simple color={"primary"} justIcon={true} onClick={copyToClipboard}>
      <Copy/>
    </Button>
  );
}

export default ClipboardButton;