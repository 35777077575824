import React from 'react';
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Button from "components/CustomButtons"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

import {incidentService} from "_services"

import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";

import extendedTablesStyle from "./extendedTablesStyle.jsx"

import Table from "../../components/Table";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import {get_sorted_devices} from "../helper_functions/sorting";
import MyDropdown from "../../components/MyDropdown";
import Loader from "../../components/Loader";


class IncidentExport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      devices: [],
      start_date_time: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
      end_date_time: new Date().toISOString(),
      language: "en",
      selected_devices: [],
      in_export: false
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  componentDidMount() {
    this._is_mounted = true
    let userLanguage = window.navigator.userLanguage || window.navigator.language;
    let lang = userLanguage.split('-')[0]

    try {
      let moment = require('moment/locale/' + userLanguage)
      moment.locale(userLanguage)
      this.setState({
        language: userLanguage,
      })
    } catch {
      try {
        let moment = require('moment/locale/' + lang)
        moment.locale(lang)
        this.setState({
          language: lang,
        })
      } catch {
        console.error("Could not load locale " + lang)
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  export() {
    const {start_date_time, end_date_time, selected_devices} = this.state
    this.setState({in_export: true})
    incidentService.exportFile(selected_devices, start_date_time, end_date_time, "incident_export.xlsx", res => {
      this.setState({in_export: false})
    }, error => {
      this.setState({in_export: false})
    })
  }

  handle_form_input_selector(name, value) {
    this.setState({selected_devices: value})
  }

  handleDateChangeRaw = (e) => {
    e.preventDefault();
  }

  handle_date_selector(state_name, datetime) {
    this.setState({[state_name]: datetime.toISOString()})
  }

  render() {
    const {start_date_time, end_date_time, language, selected_devices, in_export} = this.state

    let all_devices = get_sorted_devices()
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader><h4 style={{color: primaryColor}}>
              <b>{"Export"}</b></h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} md={6}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <h6>Start Date</h6>
                      <FormControl fullWidth>
                        <Datetime
                          timeConstraints={{
                            hours: {},
                            minutes: {step: 5}
                          }}
                          locale={language}
                          onChangeRaw={this.handleDateChangeRaw}
                          onChange={this.handle_date_selector.bind(this, "start_date_time")}
                          inputProps={{
                            placeholder: "Select Start Time",
                          }}
                          defaultValue={start_date_time}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12}>
                      <h6>End Date</h6>
                      <FormControl fullWidth>
                        <Datetime
                          timeConstraints={{
                            hours: {},
                            minutes: {step: 5}
                          }}
                          locale={language}
                          onChangeRaw={this.handleDateChangeRaw}
                          onChange={this.handle_date_selector.bind(this, "end_date_time")}
                          inputProps={{
                            placeholder: "Select End Time",
                          }}
                          defaultValue={end_date_time}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12}>
                      <h6>Devices</h6>
                      <MyDropdown
                        input_label={"Devices"}
                        name={"device_serial"}
                        value={selected_devices}
                        default_value={[]}
                        handle_selection={
                          this.handle_form_input_selector.bind(this)
                        }
                        multiple={true}
                        options={[
                          ...all_devices.map(function (item, key) {
                            return {
                              id: item.serial,
                              value: item.name
                            }
                          })
                        ]}
                      />
                    </GridItem>
                  </GridContainer>

                </GridItem>
                <GridItem xs={12} md={6} style={{textAlign: "center", justify: "center"}}>
                  <Table
                    tableHeaderColor="primary"
                    style={{fontSize: '10px'}}
                    tableData={[
                      [
                        <b>Start Time</b>, new Date(start_date_time).toLocaleString("nl-BE")
                      ],
                      [
                        <b>End Time</b>, new Date(end_date_time).toLocaleString("nl-BE")
                      ],
                      [
                        <b>Devices</b>, <ul>
                        {selected_devices.map((device_serial) => {
                          let device_name = "Unknown"
                          all_devices.forEach((device) => {
                            if (device["serial"] === device_serial)
                              device_name = device["name"]
                          })
                          return <li>{device_name}</li>
                        })}
                      </ul>
                      ],
                      ["", ""]
                    ]}
                  />
                  <Button disabled={in_export} color={in_export ? "grey" : "primary"} fullWidth
                          onClick={this.export.bind(this)}>Export</Button>
                  {in_export && <Loader/>}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

function mapStateToProps(state) {
  const {error_codes} = state.device_fleet
  return {
    error_codes
  }
}

export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(IncidentExport))
