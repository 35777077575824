import Tooltip from "@material-ui/core/Tooltip";
import {
  Battery20TwoTone,
  Battery30TwoTone,
  Battery50TwoTone,
  Battery60TwoTone,
  Battery80TwoTone,
  Battery90TwoTone,
  BatteryFullTwoTone,
  SignalCellularAlt,
  SignalCellularConnectedNoInternet4BarTwoTone,
  Wifi,
  WifiOff
} from "@material-ui/icons";
import React from "react";
import {dangerColor, successColor, warningColor} from "../../assets/jss/material-dashboard-pro-react";
import {deviceService, managementService, systemService, xenionService} from "../../_services";
import {deviceFleetActions} from "../../_actions";
import {store} from '_helpers/store'

export function get_connection_icon(classes, is_live, last_seen, has_wifi) {
  return is_live ?
    (
      <Tooltip
        id="tooltip"
        title={new Date(Date.parse(last_seen)).toLocaleString('nl-BE')}
        placement="left"
        classes={{tooltip: classes.tooltip}}>
        {has_wifi ?
          <Wifi fontSize="small" style={{color: "rgb(127, 175, 12)"}}/>
          :
          <SignalCellularAlt fontSize="small" style={{color: "rgb(127, 175, 12)"}}/>
        }
      </Tooltip>
    )
    :
    (
      <Tooltip
        id="tooltip"
        title={new Date(Date.parse(last_seen)).toLocaleString('nl-BE')}
        placement="left"
        classes={{tooltip: classes.tooltip}}>
        {has_wifi ?
          <WifiOff fontSize="small" style={{color: "rgb(175,12,12)"}}/>
          :
          <SignalCellularConnectedNoInternet4BarTwoTone fontSize="small"
                                                        style={{color: "rgb(175,12,12)"}}/>
        }
      </Tooltip>
    )
}

export function get_battery_icon(battery_level, only_icon) {
  let icon = (battery_level === undefined || battery_level < 0 ? null : (
    battery_level <= 20 ? (
      <Battery20TwoTone style={{color: dangerColor, marginBottom: "-5px", marginRight: "10px"}}/>
    ) : (
      battery_level < 30 ? (
        <Battery30TwoTone style={{color: warningColor, marginBottom: "-5px", marginRight: "10px"}}/>
      ) : (
        battery_level < 50 ? (
          <Battery50TwoTone style={{color: warningColor, marginBottom: "-5px", marginRight: "10px"}}/>
        ) : (
          battery_level < 60 ? (
            <Battery60TwoTone style={{color: warningColor, marginBottom: "-5px", marginRight: "10px"}}/>
          ) : (
            battery_level < 80 ? (
              <Battery80TwoTone
                style={{color: successColor, marginBottom: "-5px", marginRight: "10px"}}/>
            ) : (
              battery_level < 90 ? (
                <Battery90TwoTone
                  style={{color: successColor, marginBottom: "-5px", marginRight: "10px"}}/>
              ) : (
                battery_level <= 100 ? (
                  <BatteryFullTwoTone
                    style={{color: successColor, marginBottom: "-5px", marginRight: "10px"}}/>
                ) : null
              )
            )
          )
        )
      )
    )
  ))
  if (only_icon === true) {
    return icon
  }
  return <div>{icon} {battery_level === undefined || battery_level < 0 ? ('-') : (Math.round((battery_level + Number.EPSILON) * 100) / 100 + ' %')}</div>
}

export function time_to_h_and_min(time_diff_seconds) {
  const sec = parseInt(time_diff_seconds, 10); // convert value to number if it's string
  let hours = Math.floor(sec / 3600); // get hours
  let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
  let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
}


export function update_device_lists() {
  managementService.getAllDevices(m => {
    store.dispatch(deviceFleetActions.updateAll(m.result))
  })

  systemService.getCommissionedDevices(m => {
    store.dispatch(deviceFleetActions.updateCommissionedDeviceList(m.result))
  })
}

export function update_device_detail() {
  let device_fleet = store.getState()["device_fleet"]
  if (device_fleet === undefined) {
    return
  }
  let device_serial = device_fleet["device_serial"]
  deviceService.getDeviceInControl(device_serial, res => {
    store.dispatch(deviceFleetActions.updateDeviceDetail({"in_control": res.result}))
  })
  deviceService.getDeviceState(device_serial, res => {
    store.dispatch(deviceFleetActions.updateDeviceDetail({"state": res.result, "state_last_updated": res.updated}))
  })
}


export function update_error_codes() {
  xenionService.getAllErrorCodes(res => {
    let error_codes = {}
    res.error_codes.forEach((error_code) => {
      error_codes[error_code["error_code"]] = error_code
    })
    store.dispatch(deviceFleetActions.updateErrorCodes(error_codes))
  })
}