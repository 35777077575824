import {locationConstants} from '_constants'

const initialState = {
  locations: {},
  total_nr_locations: -1,
  maps: {},
  total_nr_maps: -1,
  organisations: {},
  total_nr_organisations: -1,
  greenhouses: {},
  total_nr_greenhouses: -1,
  deployments: {},
  total_nr_deployments: -1,
};

export function location(state = initialState, action) {
  switch (action.type) {
    case locationConstants.UPDATE_LOCATIONS:
      let locations = {}
      action.locations.forEach((loc) => {
        locations[loc["id"]] = loc
      })

      return {
        ...state,
        locations,
        total_nr_locations: action.total_nr_locations,
      };
    case locationConstants.UPDATE_DEPLOYMENTS:
      let deployments = {}
      action.deployments.forEach((deployment) => {
        deployments[deployment["id"]] = deployment
      })

      return {
        ...state,
        deployments,
        total_nr_deployments: action.total_nr_deployments,
      };
    case locationConstants.UPDATE_ORGANISATIONS:
      let organisations = {}
      action.organisations.forEach((org) => {
        organisations[org["id"]] = org
      })

      return {
        ...state,
        organisations,
        total_nr_organisations: action.total_nr_organisations,
      };
    case locationConstants.UPDATE_MAPS:
      let maps = {}

      action.maps.forEach((map_) => {
        maps[map_["id"]] = map_
      })

      return {
        ...state,
        maps,
        total_nr_maps: action.total_nr_maps,
      };
    case locationConstants.UPDATE_GREENHOUSES:
      let greenhouses = {}
      action.greenhouses.forEach((gh) => {
        greenhouses[gh["id"]] = gh
      })

      return {
        ...state,
        greenhouses,
        total_nr_greenhouses: action.total_nr_greenhouses,
      };
    default:
      return state
  }
}
