import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// Assets
import userProfileStyles from "./userProfileStyles.jsx"
import CardHeader from "../../components/CardHeader";
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import GridContainer from "../../components/GridContainer";
import GridItem from "../../components/GridItem";
import Card from "../../components/Card";
import CardBody from "../../components/CardBody";
import {managementService} from "../../_services";
import {filterSubstring} from "../helper_functions/table_filtering";
import MyReactTable from "../../components/MyReactTable/MyReactTable";
import {connect} from "react-redux";

class DeviceLifeCycleStages extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      all_life_cycle_stages: [],
    }
    this._is_mounted = false
    this.timer = null
  }

  componentDidMount() {
    this._is_mounted = true

    managementService.getAllLifeCycleStages(res => {
      this.setState({all_life_cycle_stages: res.result})
    })
  }

  componentWillUnmount() {
    this._is_mounted = false
    clearTimeout(this.timer)
    this.timer = null
  }

  render() {
    const {all_life_cycle_stages} = this.state

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader><h4 style={{color: primaryColor}}><b>Life Cycle Stages</b></h4></CardHeader>
            <CardBody>
              <MyReactTable
                id={"device_life_cycle_stages"}
                data={all_life_cycle_stages}
                filterable
                columns={[
                  {
                    Header: "ID",
                    accessor: "id",
                    filterMethod: (filter, row) => filterSubstring(filter, row),
                  },
                  {
                    Header: "Name",
                    accessor: "name",
                    filterMethod: (filter, row) => filterSubstring(filter, row),
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className=" -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}


function mapStateToProps(state) {
  const {all_devices} = state.device_fleet
  return {
    all_devices
  }
}

export default withStyles(userProfileStyles)(connect(mapStateToProps)(DeviceLifeCycleStages))
