import React from "react"
import ReactTable from "../react-table/es";
import Pagination from "./Pagination.jsx"

class MyReactTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultPageSize: 10,
    };
  }

  onPageSizeChange(newValue) {
    const {id} = this.props
    localStorage.setItem("defaultPageSize/" + id, newValue)

    if (this.props.onPageSizeChange !== undefined) {
      this.props.onPageSizeChange(newValue)
    }
  }

  render() {
    const {id, custom_pagination} = this.props
    let defaultPageSize = parseInt(localStorage.getItem("defaultPageSize/" + id))

    return (
      custom_pagination ?
        <ReactTable
          {...this.props}
          PaginationComponent={Pagination}
          defaultPageSize={isNaN(defaultPageSize) ? 10 : defaultPageSize}
          onPageSizeChange={this.onPageSizeChange.bind(this)}
        />
        :
        <ReactTable
          {...this.props}
          defaultPageSize={isNaN(defaultPageSize) ? 10 : defaultPageSize}
          onPageSizeChange={this.onPageSizeChange.bind(this)}
        />
    )
  }
}

export default MyReactTable
