import {deviceFleetConstants} from '_constants'

export const deviceFleetActions = {
  updateOnlineList,
  updateSerial,
  updateDeviceDetail,
  updateAll,
  updateErrorCodes,
  updateCommissionedDeviceList
};

function updateOnlineList(online_devices) {
  return {type: deviceFleetConstants.UPDATE_ONLINE_DEVICES, online_devices}
}

function updateAll(all_devices) {
  return {type: deviceFleetConstants.UPDATE_ALL_DEVICES, all_devices}
}

function updateCommissionedDeviceList(all_commissioned_devices) {
  return {type: deviceFleetConstants.UPDATE_ALL_COMMISSIONED_DEVICES, all_commissioned_devices}
}

function updateSerial(device_serial) {
  localStorage.setItem("selected_device_serial", device_serial)
  return {type: deviceFleetConstants.UPDATE_DEVICE_SERIAL, device_serial}
}

function updateDeviceDetail(device_detail) {
  return {type: deviceFleetConstants.UPDATE_DEVICE_DETAIL, device_detail}
}

function updateErrorCodes(error_codes) {
  return {type: deviceFleetConstants.UPDATE_ERROR_CODES, error_codes}
}