import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"

// Assets
import userProfileStyles from "./userProfileStyles.jsx"

// Services
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import Table from "../../components/Table";
import {get_connection_icon} from "../helper_functions/device_icons";
import ReactTimeAgo from "react-time-ago";
import {connect} from "react-redux";
import {deviceService} from "../../_services";
import GreenhouseMap from "../Maps/GreenhouseMap";
import {Icon} from "leaflet";
import {Alert} from "@mui/material";


class DockingImplementDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error_overview: [],
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  componentDidMount() {
    this._is_mounted = true
    const {device_serial} = this.props
    this.refresh(device_serial)
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.device_serial !== this.props.device_serial) {
      this.refresh(this.props.device_serial)
    }
  }

  refresh(device_serial) {
    deviceService.getErrorOverview(device_serial, 48, res => {
      this.setState({error_overview: res.result})
    })
  }

  render() {
    const {device_serial, classes, device_detail, device_name} = this.props

    let state_detail = device_detail["state"]

    if (state_detail === undefined || state_detail === null) {
      state_detail = {}
    }

    let current_location_data = [["", ""]]

    if (state_detail !== {} && state_detail["position"]) {
      current_location_data = []
      current_location_data.push([<b>Current node</b>, state_detail["position"]["value"]["node_id"]])
      current_location_data.push([<b>Current X</b>, state_detail["position"]["value"]["x"]])
      current_location_data.push([<b>Current Y</b>, state_detail["position"]["value"]["y"]])
      current_location_data.push([<b>Current theta</b>, state_detail["position"]["value"]["theta"]])
      current_location_data.push([<b>Current velocity</b>, state_detail["velocity"]["value"]])
      current_location_data.push(["", ""])
    }

    const red_icon = new Icon({
      iconUrl: require('assets/icons/Fix.svg'),
      iconSize: [50, 50],
      iconAnchor: [25, 25],
      popupAnchor: [0, -20]
    });

    return (
      <GridContainer>
        <GridItem xs={12} md={6}>
          <h5 style={{color: primaryColor}}>General</h5>
          <Table
            color={"primary"}
            tableData={[
              [
                <b>Serial Number</b>,
                device_serial
              ],
              [
                <b>Name</b>,
                <div>
                  {state_detail["is_live"] && state_detail["has_wifi"] && get_connection_icon(classes, state_detail["is_live"]["value"], state_detail["last_seen"], state_detail["has_wifi"]["value"])}
                  &nbsp; &nbsp;
                  {state_detail["name"]}
                </div>
              ],
              [
                <b>Last Connection</b>,
                state_detail["last_seen"] ? (
                  <span>
                                        {new Date(Date.parse(state_detail["last_seen"])).toLocaleString('nl-BE')}
                    &nbsp;&nbsp;(&nbsp;<ReactTimeAgo date={state_detail["last_seen"]}
                                                     locale="en-UK"/>&nbsp;)
                                        </span>
                ) : "Never connected"
              ],
              ["", ""]
            ]}/>
          <h5 style={{color: primaryColor}}>State</h5>
          <Table
            color={"primary"}
            tableData={[
              [
                <b>State</b>,
                <div>
                  {state_detail["state"] ? state_detail["state"]["value"] : ""}
                </div>
              ],
              ["", ""]
            ]}/>
        </GridItem>
        <GridItem xs={12} md={6}>
          <h5 style={{color: primaryColor}}>Robot Platform</h5>
          {window.CONTINENTAL_INTEGRATION_ENABLED ?
          <Table
            color={"primary"}
            tableData={[
              [
                <b>Last Seen</b>,
                "",
                state_detail["rp_last_seen"] && state_detail["rp_last_seen"]["value"] ? (
                  <span>
                                    {new Date(Date.parse(state_detail["rp_last_seen"]["value"])).toLocaleString('nl-BE')}
                    &nbsp;&nbsp;(&nbsp;<ReactTimeAgo date={state_detail["rp_last_seen"]["value"]}
                                                     locale="en-UK"/>&nbsp;)
                                    </span>
                ) : ""
              ],
              [
                <b>Up Time</b>,
                "",
                state_detail["rp_uptime_hours"] && state_detail["rp_uptime_hours"]["value"] ? (
                  state_detail["rp_uptime_hours"]["value"] + " h"
                ) : ""
              ],
              [
                <b>Location</b>,
                "Lane ID",
                state_detail["rp_location"] && state_detail["rp_location"]["value"] ? (
                  state_detail["rp_location"]["value"]["lane_id"]
                ) : ""
              ],
              [
                <b>Current Mission</b>,
                "Mission ID",
                state_detail["rp_current_mission_info"] && state_detail["rp_current_mission_info"]["value"] ? (
                  state_detail["rp_current_mission_info"]["value"]["mission_id"]
                ) : ""
              ],
              [
                "",
                "Execution State",
                state_detail["rp_current_mission_info"] && state_detail["rp_current_mission_info"]["value"] ? (
                  state_detail["rp_current_mission_info"]["value"]["execution_status"]
                ) : ""
              ],
              [
                "",
                "Elapsed Time",
                state_detail["rp_current_mission_info"] && state_detail["rp_current_mission_info"]["value"] ? (
                  state_detail["rp_current_mission_info"]["value"]["elapsed_seconds"] + " s"
                ) : ""
              ],
              [
                "",
                "Time Remaining",
                state_detail["rp_current_mission_info"] && state_detail["rp_current_mission_info"]["value"] ? (
                  state_detail["rp_current_mission_info"]["value"]["expected_completion_seconds"] + " s"
                ) : ""
              ],
              [
                <b>Battery</b>,
                "State",
                state_detail["rp_battery_state"] && state_detail["rp_battery_state"]["value"] ? (
                  state_detail["rp_battery_state"]["value"]["status"]
                ) : ""
              ],
              [
                "",
                "Percentage",
                state_detail["rp_battery_state"] && state_detail["rp_battery_state"]["value"] ? (
                  state_detail["rp_battery_state"]["value"]["percentage"] + " %"
                ) : ""
              ],
              [
                "",
                "Voltage",
                state_detail["rp_battery_state"] && state_detail["rp_battery_state"]["value"] ? (
                  state_detail["rp_battery_state"]["value"]["voltage"] + " V"
                ) : ""
              ],
              [
                "",
                "Current",
                state_detail["rp_battery_state"] && state_detail["rp_battery_state"]["value"] ? (
                  state_detail["rp_battery_state"]["value"]["current"] + " A"
                ) : ""
              ],
              [
                <b>Network State</b>,
                "Connection Type",
                state_detail["rp_network_state"] && state_detail["rp_network_state"]["value"] ? (
                  state_detail["rp_network_state"]["value"]["connection_type"]
                ) : ""
              ],
              [
                "",
                "Data Consumption (Mobile - last month)",
                state_detail["rp_network_state"] && state_detail["rp_network_state"]["value"] && state_detail["rp_network_state"]["value"]["mobile_data_consumption"] ? (
                  state_detail["rp_network_state"]["value"]["mobile_data_consumption"]["last_one_month"] + " ?? "
                ) : ""
              ],
              [
                "",
                "Data Consumption (Mobile - last week)",
                state_detail["rp_network_state"] && state_detail["rp_network_state"]["value"] && state_detail["rp_network_state"]["value"]["mobile_data_consumption"] ? (
                  state_detail["rp_network_state"]["value"]["mobile_data_consumption"]["last_one_week"] + " ?? "
                ) : ""
              ],
              ["", "", ""]
            ]}
          /> :
            <Alert severity={"warning"}>The integration with Continental is disabled, for more information contact your Nimbus Developer</Alert>
          }
        </GridItem>
        <GridItem xs={12} md={12}>
          <h5 style={{color: primaryColor}}>Current Location</h5>
          {state_detail["site_id"] && state_detail["site_id"]["value"] && state_detail["site_version"] && state_detail["site_version"]["value"] && state_detail["position"] &&
            <GreenhouseMap
              map_id={state_detail["site_id"]["value"]}
              map_version={state_detail["site_version"]["value"]}
              markers={[{
                "key": "current_position",
                "popup": device_name,
                "x": state_detail["position"] ? state_detail["position"]["value"]["x"] : 0,
                "y": state_detail["position"] ? state_detail["position"]["value"]["y"] : 0,
                "icon": red_icon,
              }]
              }
              alternate={
                <Table
                  color={"primary"}
                  tableData={current_location_data}/>
              }
            />
          }
        </GridItem>
      </GridContainer>
    )
  }
}

function mapStateToProps(state) {
  const {device_detail, device_serial} = state.device_fleet


  let device_name = ""
  if (state.device_fleet && state.device_fleet.device_detail && state.device_fleet.device_detail.state) {
    device_name = state.device_fleet.device_detail.state.name
  }
  return {
    device_detail, device_serial, device_name
  }
}


export default withStyles(userProfileStyles)(connect(mapStateToProps)(DockingImplementDetail))
