import React from "react"
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

import extendedTablesStyle from "./extendedTablesStyle.jsx"

import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import {mapService} from "../../_services";

import ReactTable from "../../components/react-table";
import Button from "../../components/CustomButtons";
import {sweetAlertActions} from "../../_actions";
import CustomInput from "../../components/CustomInput";
import ClipboardButton from "../../components/ClipboardButoon";
import {update_greenhouse_list} from "../helper_functions/locations";
import MyDropdown from "../../components/MyDropdown";
import moment from "moment-timezone";
import Table from "../../components/Table";

class Greenhouses extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      totalResults: 0,
      totalPages: 0,
      pageSize: 10,
      currentPage: 1,
      loading: false,
      updated_properties: {},
      data: [],
      update_greenhouse: null,
      create_greenhouse: false
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  componentDidMount() {
    this._is_mounted = true
    this.refresh()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  refresh() {
    const {currentPage, pageSize} = this.state
    this.setState({loading: true})

    mapService.getGreenhouses(pageSize, currentPage - 1, result => {
      const total_pages = Math.ceil(result.total / pageSize)
      let newCurrentPage = currentPage
      if (currentPage > total_pages && result.total > 0) {
        newCurrentPage = total_pages
      }
      this.setState({
        totalResults: result.total,
        currentPage: newCurrentPage,
        data: result.results,
        totalPages: total_pages,
        loading: false,
      })
    }, error => {
      this.setState({
        data: [],
        totalPages: 0,
        loading: false
      })
    });
  }

  change_value(value_name, value) {
    let {updated_properties} = this.state
    updated_properties[value_name] = value
    this.setState({updated_properties})
  }

  start_creating_greenhouse() {
    this.setState({create_greenhouse: true})
  }

  start_updating_greenhouse(greenhouse) {
    this.setState({update_greenhouse: greenhouse})
  }

  do_update_greenhouse(greenhouse) {
    const {updated_properties} = this.state
    const {dispatch} = this.props;

    let greenhouse_id = greenhouse["id"]
    let new_name = undefined
    if (updated_properties["name"] !== undefined && updated_properties["name"] !== "") {
      if (updated_properties["name"] !== greenhouse["name"]) {
        new_name = updated_properties["name"]
      }
    }

    let new_map_id = undefined
    if (updated_properties["map_id"] !== undefined && updated_properties["map_id"] !== "") {
      if (updated_properties["map_id"] !== greenhouse["map_id"]) {
        new_map_id = updated_properties["map_id"]
      }
    }

    let new_timezone = undefined
    if (updated_properties["timezone"] !== undefined && updated_properties["timezone"] !== "") {
      if (updated_properties["timezone"] !== greenhouse["timezone"]) {
        new_timezone = updated_properties["timezone"]
      }
    }

    let new_location_id = undefined
    if (updated_properties["location_id"] !== undefined && updated_properties["location_id"] !== "") {
      if (updated_properties["location_id"] !== greenhouse["location_id"]) {
        new_location_id = updated_properties["location_id"]
      }
    }

    mapService.updateGreenhouse(greenhouse_id, new_name, new_map_id, new_timezone, new_location_id, res => {
      dispatch(sweetAlertActions.success({title: "Greenhouse is updated"}))
      update_greenhouse_list()
      this.setState({
        updated_properties: {},
        update_greenhouse: null
      }, () => this.refresh())
    })
  }

  create_new_greenhouse() {
    const {updated_properties} = this.state
    const {dispatch} = this.props;
    mapService.createGreenhouse(updated_properties["name"], updated_properties["location_id"], updated_properties["map_id"], updated_properties["timezone"], res => {
      dispatch(sweetAlertActions.success({title: "Greenhouse is created"}))
      update_greenhouse_list()
      this.setState({
        updated_properties: {},
        create_greenhouse: false,
      }, () => this.refresh())
    })
  }

  render() {
    const {locations, nimbus_manager} = this.props
    const {
      update_greenhouse,
      currentPage,
      data,
      pageSize,
      loading,
      totalResults,
      totalPages,
      create_greenhouse,
      updated_properties
    } = this.state

    let filler_size = (currentPage - 1) * pageSize
    if (filler_size < 0) {
      filler_size = 0
    }
    let filler_array = Array(filler_size).keys()

    return (
      <GridContainer>
        {create_greenhouse &&
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <GridContainer>
                  <GridItem xs={10}>
                    <h4 style={{color: primaryColor}}><b>{"Create Greenhouse"}</b></h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div>
                  <CustomInput
                    style={{
                      fullWidth: true,
                      width: "100%"
                    }}
                    id={"name"}
                    labelText={"Name"}
                    inputProps={{
                      onChange: event => this.change_value("name", event.target.value),
                      type: "text",
                      value: updated_properties["name"]
                    }}
                  />
                  <MyDropdown
                    input_label={"Location"}
                    name={"location_id"}
                    value={updated_properties["location_id"]}
                    handle_selection={
                      this.change_value.bind(this)
                    }
                    options={[
                      ...Object.keys(locations).sort((a, b)=>{
                         return locations[a]["name"] < locations[b]["name"] ? -1 : 1
                      }).map((location_id) => {
                        return {
                          id: location_id,
                          value: locations[location_id]["name"]
                        }
                      })
                    ]}
                  />
                  <CustomInput
                    style={{
                      fullWidth: true,
                      width: "100%"
                    }}
                    id={"map_id"}
                    labelText={"Map ID"}
                    inputProps={{
                      onChange: event => this.change_value("map_id", event.target.value),
                      type: "text",
                      value: updated_properties["map_id"]
                    }}
                  />
                  <MyDropdown
                    input_label={"Timezone"}
                    name={"timezone"}
                    value={updated_properties["timezone"]}
                    handle_selection={
                      this.change_value.bind(this)
                    }
                    options={[
                      ...moment.tz.names().map((tz) => {
                        return {
                          id: tz,
                          value: tz
                        }
                      })
                    ]}
                  />
                </div>
                <Button simple color={"primary"} onClick={this.create_new_greenhouse.bind(this)}> Create </Button>
                <Button simple color={"danger"} onClick={() => {
                  this.setState({create_greenhouse: false, updated_properties: {}})
                }}> Cancel </Button>
              </CardBody>
            </Card>
          </GridItem>
        }
        {update_greenhouse &&
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <GridContainer>
                  <GridItem xs={10}>
                    <h4 style={{color: primaryColor}}><b>{"Create Greenhouse"}</b></h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12}>
                    <p><b>Leave empty if you do not want to update the property</b></p>
                  </GridItem>
                  <GridItem xs={12}>
                    <Table
                      tableData={[
                        [
                          <p><b>Name:</b> {update_greenhouse["name"]}</p>,
                          <CustomInput
                            style={{
                              fullWidth: true,
                              width: "100%"
                            }}
                            id={"name"}
                            labelText={"New name"}
                            inputProps={{
                              onChange: event => this.change_value("name", event.target.value),
                              type: "text",
                              value: updated_properties["name"]
                            }}
                          />
                        ],
                        [
                          <p><b>Map ID:</b> {update_greenhouse["map_id"]}</p>,
                          <CustomInput
                            style={{
                              fullWidth: true,
                              width: "100%"
                            }}
                            id={"map_id"}
                            labelText={"New Map ID"}
                            inputProps={{
                              onChange: event => this.change_value("map_id", event.target.value),
                              type: "text",
                              value: updated_properties["map_id"]
                            }}
                          />
                        ],
                        [
                          <p><b>Timezone: </b> {update_greenhouse["timezone"]}</p>,
                          <MyDropdown
                            input_label={"New Timezone"}
                            name={"timezone"}
                            value={updated_properties["timezone"]}
                            handle_selection={
                              this.change_value.bind(this)
                            }
                            options={[
                              ...moment.tz.names().map((tz) => {
                                return {
                                  id: tz,
                                  value: tz
                                }
                              })
                            ]}
                          />
                        ],
                        [
                          <p>
                            <b>Location Name: </b>
                            {locations[update_greenhouse["location_id"]] ?
                              locations[update_greenhouse["location_id"]]["name"] : update_greenhouse["location_id"]
                            }
                            {nimbus_manager &&
                              <p><b>Location ID: </b> {update_greenhouse["location_id"]}</p>
                            }
                          </p>,
                          nimbus_manager ?
                            <MyDropdown
                              input_label={"Location"}
                              name={"location_id"}
                              value={updated_properties["location_id"]}
                              handle_selection={
                                this.change_value.bind(this)
                              }
                              options={[
                                  ...Object.keys(locations).sort((a, b) => {
                                    return locations[a]["name"] < locations[b]["name"] ? -1 : 1
                                  }).map((location_id) => {
                                  return {
                                    id: location_id,
                                    value: locations[location_id]["name"]
                                  }
                                })
                              ]}
                            /> :
                            <p>Can only be updated by your Cloud Manager</p>
                        ],
                        ["", ""],
                      ]}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <Button simple color={"primary"} onClick={this.do_update_greenhouse.bind(this, update_greenhouse)}> Update </Button>
                    <Button
                      simple
                      color={"danger"}
                      onClick={() => {
                        this.setState({update_greenhouse: null, updated_properties: {}})
                      }}
                    >
                      Cancel
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        }
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem xs={10}>
                  <h4 style={{color: primaryColor}}><b>{"Overview Greenhouses"}</b></h4>
                </GridItem>
                <GridItem xs={2} style={{textAlign: "right"}}>
                  <Button color="primary" simple
                          onClick={this.start_creating_greenhouse.bind(this)}><b>{"Create Greenhouse"}</b></Button>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <h5># Results: {totalResults}</h5>
              <ReactTable
                columns={
                  [
                    {
                      Header: "ID",
                      accessor: "id",
                      filterable: false,
                      sortable: false,
                      maxWidth: 50,
                      Cell: row => {
                        return <ClipboardButton text={row.value}/>
                      }
                    },
                    {
                      Header: "Name",
                      accessor: "name",
                    },
                    // {
                    //     Header: "Continental ID",
                    //     accessor: "continental_id",
                    // },
                    {
                      Header: "Location",
                      accessor: "location_id",
                      Cell: row => {
                        if (locations && locations[row.value]) {
                          return locations[row.value]["name"]
                        }
                        return row.value
                      }
                    },
                    {
                      Header: "Map ID",
                      accessor: "map_id",
                    },
                    {
                      Header: "Timezone",
                      accessor: "timezone",
                      Cell: row => {
                        return moment.tz.names().includes(row.value) ?
                          row.value :
                          row.value ?
                            <span style={{color: "rgb(231,92,92)"}}>{row.value}</span>:
                            <span style={{color: "rgb(231,92,92)"}}>No Timezone</span>
                      }
                    },
                    {
                      Header: "Actions",
                      accessor: "id",
                      Cell: row => {
                        return <Button color="primary" simple
                                       onClick={this.start_updating_greenhouse.bind(this, row.original)}><b>{"Update Greenhouse"}</b></Button>
                      }
                    }
                  ]}
                data={[
                  ...filler_array,
                  ...data
                ]}
                sortable={false}
                filterable={false}
                loading={loading}
                pages={totalPages}
                page={currentPage - 1}
                pageSize={pageSize}
                onPageChange={(pageIndex) => this.setState({currentPage: pageIndex + 1}, () => this.refresh())}
                onPageSizeChange={(newPageSize) => {
                  this.setState({currentPage: 1, pageSize: newPageSize}, () => this.refresh())
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}


function mapStateToProps(state) {
  const {user} = state.authentication
  const {nimbus_manager} = state.authentication.user
  const {locations} = state.location
  return {
    user, locations, nimbus_manager
  }
}


export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(Greenhouses))
