import SuperAgent from 'superagent'
import {api_root} from 'index.js'
import {errorHandling, unauthorizedRedirect} from './error.handler.js'
import {getKeycloak} from 'react-router-keycloak';


export const configService = {
  setConfig,
  compareConfigs,
  downloadConfigFolder,
  getChanges
};

function getChanges(device_serial, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/config/changes/" + device_serial)
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function setConfig(device_serial, file_name, config_name, new_value, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .post(api_root + "/machine/" + device_serial + "/config")
  .send({file_name})
  .send({config_name})
  .send({new_value})
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function compareConfigs(machine_serial_1, machine_serial_2, at_time_machine_1, at_time_machine_2, callback, reject) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/config/compare")
  .query({machine_serial_1})
  .query({machine_serial_2})
  .query({at_time_machine_1})
  .query({at_time_machine_2})
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function downloadConfigFolder(device_serial, at_time) {
  const keycloak = getKeycloak()
  return SuperAgent
  .get(api_root + "/config/download")
  .query({machine_serial: device_serial})
  .query({at_time})
  .withCredentials()
  .set('Authorization', 'Bearer ' + keycloak.token)
  .use(unauthorizedRedirect)
  .responseType('arraybuffer')
  .then((response) => {
    const FileSaver = require('file-saver');
    const blob = new Blob([response.body], {type: response.type});
    FileSaver.saveAs(blob, "CFG_" + device_serial + ".zip")
  })
  .catch(errorHandling)
}