import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

// Assets
import userProfileStyles from "./userProfileStyles.jsx"

// Services
import {deviceService} from '_services'
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";

import {Alert} from "@mui/material";
import Button from "../../components/CustomButtons";
import {connect} from "react-redux";
import {alertActions, sweetAlertActions} from "../../_actions";
import Table from "../../components/Table";
import {getKeycloak} from 'react-router-keycloak';
import DeviceSelector from "./DeviceSelector";


class DeviceCommands extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this._is_mounted = false
  }

  componentDidMount() {
    this._is_mounted = true
    const {device_serial} = this.props
    this.refresh(device_serial)
  }

  componentWillUnmount() {
    this._is_mounted = false
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.device_serial !== this.props.device_serial) {
      this.refresh(this.props.device_serial)
    }
  }

  refresh(device_serial) {
  }

  stop_immediately() {
    const {device_serial, dispatch} = this.props
    deviceService.stopImmediately(device_serial, res => {
      dispatch(sweetAlertActions.clear())
      dispatch(alertActions.success("The command is sent to the device"))
    })
  }


  clear_popup() {
    const {dispatch} = this.props;
    dispatch(sweetAlertActions.clear())
  }

  confirm_stop_immediately() {
    const {dispatch} = this.props
    dispatch(sweetAlertActions.clean({
      title: "Are you sure?",
      showCancel: true,
      onCancel: this.clear_popup.bind(this),
      cancelBtnText: "No",
      onConfirm: this.stop_immediately.bind(this),
      confirmBtnText: "Yes",
      content: ''
    }))
  }

  confirm_upload_all_incident_data() {
    const {dispatch} = this.props
    dispatch(sweetAlertActions.clean({
      title: "Are you sure?",
      showCancel: true,
      onCancel: this.clear_popup.bind(this),
      cancelBtnText: "No",
      onConfirm: this.upload_all_incident_data.bind(this),
      confirmBtnText: "Yes",
      content: ''
    }))

  }

  upload_all_incident_data() {
    const {device_serial, dispatch} = this.props
    deviceService.uploadAllIncidentData(device_serial, res => {
      dispatch(sweetAlertActions.clear())
      dispatch(alertActions.success("The command is sent to the device"))
    })

  }

  confirm_validate_maps() {
    const {dispatch} = this.props
    dispatch(sweetAlertActions.clean({
      title: "Are you sure?",
      showCancel: true,
      onCancel: this.clear_popup.bind(this),
      cancelBtnText: "No",
      onConfirm: this.validate_maps.bind(this),
      confirmBtnText: "Yes",
      content: ''
    }))

  }

  validate_maps() {
    const {device_serial, dispatch} = this.props
    deviceService.validateMaps(device_serial, res => {
      dispatch(sweetAlertActions.clear())
      dispatch(alertActions.success("The command is sent to the device"))
    })

  }

  render() {
    const {is_live, in_control, current_state, device_name, all_maps_valid} = this.props
    const keycloak = getKeycloak()

    let has_executor_access = false
    if (keycloak && keycloak.tokenParsed && keycloak.tokenParsed.resource_access && keycloak.tokenParsed.resource_access["command-api"]) {
      if ("roles" in keycloak.tokenParsed.resource_access["command-api"]) {
        if (keycloak.tokenParsed.resource_access["command-api"]["roles"].includes("executor")) {
          has_executor_access = true
        }
      }
    }

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <DeviceSelector disable_key_bindings={true}/>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader><h4 style={{color: primaryColor}}><b>Device Commands - {device_name}</b></h4></CardHeader>
              <CardBody>
                {!has_executor_access &&
                  <Alert severity={"warning"}> You do not have the correct roles to execute commands </Alert>}
                {!is_live && <Alert severity={"warning"}>You cannot execute commands, the device is not online </Alert>}
                {is_live && !in_control &&
                  <Alert severity={"warning"}>Not all commands are available, you are not in control of the
                    device </Alert>}

                {has_executor_access && is_live &&
                  <div>
                    <Alert severity={"success"}> You can execute commands. Mind the device state, it
                      is: <b>{current_state}</b></Alert>
                    <br/>
                    <Table
                      tableHeaderColor="primary"
                      style={{fontSize: '10px'}}
                      tableData={[
                        [
                          <p>Stop the action the device is doing</p>,
                          <Button color={'danger'} onClick={this.confirm_stop_immediately.bind(this)}>Stop
                            Immediately</Button>,
                          <p> Current State: <b>{current_state}</b></p>
                        ],
                        [
                          <p>Upload all the incident data, the device must be in idle. You can click on stop immediately
                            to make this happen<br/>
                          This upload will only work when the device is connected to WiFi. To force update through 4G, upload the logs individually
                          </p>,
                          <Button color={'primary'} onClick={this.confirm_upload_all_incident_data.bind(this)}>Upload
                            All</Button>,
                          ""
                        ],
                        [
                          <p>Ask the device to validate all maps it has downloaded</p>,
                          <Button color={'primary'} onClick={this.confirm_validate_maps.bind(this)}>Validate
                            Maps</Button>,
                          <p> All Maps Valid: <b>{all_maps_valid === null ? "-" : all_maps_valid ? "Yes" : "No"}</b></p>
                        ],
                        [
                          <p>Start Treatment</p>,
                          "Coming Soon",
                          ""
                        ],
                        [
                          <p>Finish Current Lane</p>,
                          "Coming Soon",
                          ""
                        ],
                        [
                          <p>Return On Rails Lane</p>,
                          "Coming Soon",
                          ""
                        ],
                        [
                          <p>Reset Side Shift</p>,
                          "Coming Soon",
                          ""
                        ],
                        [
                          <p>Reset UV Counter</p>,
                          "Coming Soon",
                          ""
                        ],
                        ["", "", ""]
                      ]}
                    />


                  </div>
                }
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {device_serial} = state.device_fleet
  let is_live = false
  let in_control = false
  let current_state = "unknown"
  let all_maps_valid = null
  if (state.device_fleet && state.device_fleet.device_detail && state.device_fleet.device_detail.state && state.device_fleet.device_detail.state.state) {
    is_live = state.device_fleet.device_detail.state.is_live
    current_state = state.device_fleet.device_detail.state.state.value
  }
  if (state.device_fleet && state.device_fleet.device_detail && state.device_fleet.device_detail.state) {
    is_live = state.device_fleet.device_detail.state.is_live
    all_maps_valid = state.device_fleet.device_detail.state.all_maps_valid
  }

  if (state.device_fleet && state.device_fleet.device_detail && state.device_fleet.device_detail.in_control) {
    in_control = state.device_fleet.device_detail.in_control.in_control
  }

  let device_name = ""
  if (state.device_fleet && state.device_fleet.device_detail && state.device_fleet.device_detail.state) {
    device_name = state.device_fleet.device_detail.state.name
  }

  return {
    device_serial, is_live, in_control, current_state, device_name, all_maps_valid
  }
}

export default connect(mapStateToProps)(withStyles(userProfileStyles)(DeviceCommands))
