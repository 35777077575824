import React from "react"
import {connect} from 'react-redux'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Clearfix from "components/Clearfix"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"
import CardIcon from "components/CardIcon"
import Table from "components/Table"

// assets
import userProfileStyles from "./userProfileStyles.jsx"
import {getKeycloak} from "react-router-keycloak";

// Services

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }


  render() {
    const {classes} = this.props;

    let keycloak = getKeycloak()

    let user = {
      "email": "",
      "first_name": "",
      "name": "",
    }

    if (keycloak.idToken) {
      user["email"] = keycloak.idTokenParsed.email
      user["name"] = keycloak.idTokenParsed.family_name
      user["first_name"] = keycloak.idTokenParsed.given_name
    }
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <PermIdentity/>
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>
                    {"Profile"}
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Table
                        tableHeaderColor="primary"
                        style={{fontSize: '10px'}}
                        tableData={[
                          [<b>{"First Name"}</b>, user["first_name"]],
                          [<b>{"Last Name"}</b>, user["name"]],
                          [<b>{"Mail Address"}</b>, user["email"]],
                          ["", ""]
                        ]}
                      />
                    </GridItem>
                  </GridContainer>
                  <Clearfix/>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    )
  }
}

function mapStateToProps(state) {
  const {user} = state.authentication;
  return {
    user
  }
}


export default withStyles(userProfileStyles)(connect(mapStateToProps)(Account))
