import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {connect} from 'react-redux';

// import { Manager, Target, Popper } from "react-popper";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import ExitToApp from "@material-ui/icons/ExitToApp";

// core components
import Button from "components/CustomButtons";


import headerLinksStyle from "./headerLinksStyle";
import {history} from "../../index";


class HeaderLinks extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openAccount: false,
    };
  }

  handleClickAccount = () => {
    this.setState({openAccount: !this.state.openAccount});
  };

  handleCloseAccount(name) {
    this.setState({openAccount: false});
    if (name === "logout") {
      history.push("/log-out")
    }
  }

  render() {
    const {classes} = this.props;
    const {openAccount} = this.state;
    const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover);
    const managerClasses = classNames({[classes.managerClasses]: true});

    return (
      <div>
        {/* Person */}
        <div className={managerClasses}>
          <Tooltip
            id="tooltip"
            title={"Log Out"}
            placement="bottom"
            classes={{tooltip: classes.tooltip}}>
            <Button
              color="transparent"
              justIcon
              aria-label={"Log Out"}
              aria-owns={openAccount ? "menu-list" : null}
              aria-haspopup="true"
              onClick={this.handleClickAccount}
              className={classes.buttonLink}
              buttonRef={node => {
                this.anchorEl = node;
              }}
            >
              <Person className={classes.headerLinksSvg + " " + classes.links}/>
              <Hidden mdUp implementation="css">
              <span onClick={this.handleClickAccount} className={classes.linkText}>
                {"Log Out"}
              </span>
              </Hidden>
            </Button>
          </Tooltip>
          <Popper
            open={openAccount}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !openAccount,
              [classes.pooperResponsive]: true,
              [classes.pooperNav]: true
            })}
          >
            {({TransitionProps, placement}) => (
              <Grow
                {...TransitionProps}
                id="menu-list"
                style={{transformOrigin: "0 0 0"}}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleCloseAccount.bind(this, "")}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={this.handleCloseAccount.bind(this, "logout")}
                        className={dropdownItem}
                        color="danger"
                      >
                        <ExitToApp style={{
                          marginBottom: '-5px',
                          marginRight: '5px',
                          color: '#f44336'
                        }}/> {"Log Out"}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const {loggingIn, user} = state.authentication;
  return {
    loggingIn, user
  };
}

export default withStyles(headerLinksStyle)(connect(mapStateToProps)(HeaderLinks))
