import React from "react"
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"

import extendedTablesStyle from "./extendedTablesStyle.jsx"

import {mapService} from "../../_services";

import 'leaflet/dist/leaflet.css';
import L from "leaflet";
import {Map, Marker, Popup} from 'react-leaflet'
import {Alert} from "@mui/material";

function get_length_width(feature) {
  let min_x = null
  let max_x = null
  let min_y = null
  let max_y = null

  feature.geometry.coordinates[0].forEach((coordinate) => {
    let x = coordinate[0]
    let y = coordinate[1]

    if (min_x === null || x < min_x) {
      min_x = x
    }
    if (max_x === null || x > max_x) {
      max_x = x
    }
    if (min_y === null || y < min_y) {
      min_y = y
    }
    if (max_y === null || y > max_y) {
      max_y = y
    }
  })

  let length = Math.round((Math.max(max_y - min_y, max_x - min_x) + Number.EPSILON) * 100) / 100
  let width = Math.round((Math.min(max_y - min_y, max_x - min_x) + Number.EPSILON) * 100) / 100
  return {
    length: length, width: width
  }
}


function general_popup_function(feature) {
  if (feature.properties.node_type === "gutter") {
    return "Gutter: " + feature.properties.gutter_number
  }
  if (feature.properties.node_type === "obstacle") {
    return "Obstacle: " + feature.properties.obstacle_type
  }
  if (feature.properties.node_type === "lane") {
    let size = get_length_width(feature)
    return `Lane: ${feature.properties.node_id} | Size: ${size['length']}m x ${size['width']}m`
  }
  if (feature.properties.node_type === "walkway") {
    let size = get_length_width(feature)
    return `Walkway: ${feature.properties.node_id} | Size: ${size['length']}m x ${size['width']}m`
  }
  return "ID: " + feature.properties.node_id
}


class GreenhouseMap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      map_id: undefined,
      map_version: undefined,
      gutter_layer_version: undefined,
      obstacle_layer_version: undefined,
      map: {},
      allow_alternate: false,
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  componentDidMount() {
    this._is_mounted = true
    this.refresh()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      map_id,
      map_version,
      gutter_layer_version,
      obstacle_layer_version,
      coverage_overlay,
      force_refresh
    } = this.props
    if (
      prevProps.map_id !== map_id ||
      prevProps.map_version !== map_version ||
      prevProps.gutter_layer_version !== gutter_layer_version ||
      prevProps.obstacle_layer_version !== obstacle_layer_version ||
      prevProps.coverage_overlay !== coverage_overlay ||
      prevProps.force_refresh !== force_refresh
    ) {
      this.refresh()
    }
  }

  refresh() {
    const {map_id, map_version, gutter_layer_version, obstacle_layer_version} = this.props
    let gutter_version = undefined
    let obstacle_version = undefined
    if (map_id === undefined || map_id === null || map_version === undefined || map_version === null) {
      return
    }
    this.setState({allow_alternate: false})

    let split_map_version = map_version.split("#")[0]
    let [major, minor, patch] = split_map_version.split(".")
    if (split_map_version.includes("-1")) {
      split_map_version = major + "." + minor + ".1"
    }

    if (gutter_layer_version !== undefined) {
      if (gutter_layer_version.startsWith(split_map_version)) {
        gutter_version = gutter_layer_version
      } else {
        gutter_version = split_map_version + "." + gutter_layer_version
      }
    }

    if (obstacle_layer_version !== undefined) {
      if (obstacle_layer_version.startsWith(split_map_version)) {
        obstacle_version = obstacle_layer_version
      } else {
        obstacle_version = split_map_version + "." + obstacle_layer_version
      }
    }

    mapService.exportGeoJson(map_id, split_map_version, gutter_version, obstacle_version, res => {
      this.setState({map: res.result}, () => {
        this.createIndoorLayers()
        this.add_coverage_overlay()
      })
    })
  }

  createIndoorLayers() {
    const {map_id, popup_function} = this.props;
    const {map, current_layer} = this.state;

    if (this.refs.mymapforheatmap === undefined) {
      return
    }
    let map_reference = this.refs.mymapforheatmap.leafletElement;
    map_reference.attributionControl = false
    if (current_layer !== undefined)
      map_reference.removeLayer(current_layer)

    if (map === undefined || Object.keys(map).length === 0 || map === null) {
      this.setState({allow_alternate: true})
      return
    }

    let indoorLayer = new L.Indoor(map, {
      getLevel: function (feature) {
        return 0;
      },
      onEachFeature: function (feature, layer) {
        layer.bindPopup(popup_function === undefined ? general_popup_function(feature) : popup_function(feature));
      }, style: this.style_function.bind(this)
    });

    indoorLayer.addTo(map_reference);
    indoorLayer.id = map_id;
    indoorLayer.setLevel(0)

    this.setState({current_layer: indoorLayer, allow_alternate: false})
  }

  add_coverage_overlay() {
    const {popup_function, coverage_overlay, coverage_overlay_width} = this.props;
    const {overlay_layer} = this.state;

    if (this.refs.mymapforheatmap === undefined) {
      return
    }
    let map_reference = this.refs.mymapforheatmap.leafletElement;
    map_reference.attributionControl = false
    if (overlay_layer !== undefined)
      map_reference.removeLayer(overlay_layer)


    if (coverage_overlay === undefined || Object.keys(coverage_overlay).length === 0 || coverage_overlay === null) {
      this.setState({allow_alternate: true})
      return
    }

    let features = []
    Object.keys(coverage_overlay).forEach((lane_id) => {
      let lane_coverage = coverage_overlay[lane_id]
      let coverage_intervals = lane_coverage["coverage"]
      coverage_intervals.forEach((coverage_interval, index) => {
        let x_start = coverage_interval["x_start"]
        let x_stop = coverage_interval["x_stop"]
        let y_start = coverage_interval["y_start"]
        let y_stop = coverage_interval["y_stop"]

        let corner_1_x = 0
        let corner_2_x = 0
        let corner_3_x = 0
        let corner_4_x = 0
        let corner_1_y = 0
        let corner_2_y = 0
        let corner_3_y = 0
        let corner_4_y = 0

        if (Math.abs(y_start - y_stop) < Math.abs(x_start - x_stop)) {
          corner_1_x = x_start
          corner_2_x = x_stop
          corner_3_x = x_stop
          corner_4_x = x_start
          corner_1_y = y_start - coverage_overlay_width
          corner_2_y = y_stop - coverage_overlay_width
          corner_3_y = y_stop + coverage_overlay_width
          corner_4_y = y_start + coverage_overlay_width
        } else {
          corner_1_x = x_start - coverage_overlay_width
          corner_2_x = x_stop - coverage_overlay_width
          corner_3_x = x_stop + coverage_overlay_width
          corner_4_x = x_start + coverage_overlay_width
          corner_1_y = y_start
          corner_2_y = y_stop
          corner_3_y = y_stop
          corner_4_y = y_start
        }

        let coordinates = [
          [corner_1_x, corner_1_y],
          [corner_2_x, corner_2_y],
          [corner_3_x, corner_3_y],
          [corner_4_x, corner_4_y],
          [corner_1_x, corner_1_y],
        ]
        let feature = {
          "geometry": {
            "coordinates": [coordinates],
            "type": "Polygon"
          },
          "id": lane_id + "___coverage___" + index,
          "properties": {
            "node_id": lane_id,
            "node_type": "coverage"
          },
          "type": "Feature"
        }
        features.push(feature)
      })
    })

    if (features.length === 0) {
      return
    }

    let coverage_overlay_collection = {
      "features": features,
      "type": "FeatureCollection"
    }

    let indoorLayer = new L.Indoor(coverage_overlay_collection, {
      getLevel: function (feature) {
        return 1;
      },
      onEachFeature: function (feature, layer) {
        layer.bindPopup(popup_function === undefined ? general_popup_function(feature) : popup_function(feature));
      }, style: this.get_overlay_style.bind(this)
    });

    indoorLayer.addTo(map_reference);
    indoorLayer.id = "coverage_overlay";
    indoorLayer.setLevel(1)

    this.setState({overlay_layer: indoorLayer})
  }

  get_overlay_style(feature) {
    return {
      fillColor: "rgba(0,44,255,0.99)", fill: true, weight: 1, color: "rgba(0,44,255,0.99)", fillOpacity: 0.125
    }
  }

  style_function(feature) {
    const {style_function} = this.props
    if (style_function !== undefined) {
      return style_function(feature)
    }

    let color = 'white';
    let fillOpacity = 0.5;
    let weight = 1;
    if (feature.properties.node_type === 'walkway') {
      color = '#0A485B';
      fillOpacity = 0.01;
    } else if (feature.properties.node_type === 'lane') {
      color = '#666';
      fillOpacity = 0.2;
      weight = 0.2;
    } else if (feature.properties.node_type === 'gutter') {
      color = '#638813';
      fillOpacity = 0.5;
    } else if (feature.properties.node_type === 'obstacle') {
      color = '#881317';
      fillOpacity = 0.5;
    }
    return {
      fillColor: color, fill: true, weight: weight, color: "#666", fillOpacity: fillOpacity
    };
  }

  get_markers() {
    const {markers} = this.props
    let parsed_markers = []

    markers.forEach((marker) => {
      parsed_markers.push({
        "position": [marker["y"], marker["x"]],
        "key": marker["key"],
        "icon": marker["icon"],
        "popup": marker["popup"]
      })
    })

    return parsed_markers
  }

  render() {
    const {map, allow_alternate} = this.state
    const {map_version, map_id, alternate} = this.props
    let split_map_version = ""

    if (map_version) {
      split_map_version = map_version.split("#")[0]
      let [major, minor, patch] = split_map_version.split(".")
      if (split_map_version.includes("-1")) {
        split_map_version = major + "." + minor + ".1"
      }
    }
    if (map === undefined || Object.keys(map).length === 0 || map === null) {
      if (allow_alternate) {
        return alternate
      }
    }

    let markers = this.get_markers()

    return (
      <GridContainer>
        <GridItem xs={12}>
          {map_version && map_version.includes("-1") &&
            <Alert variant={"outlined"} severity={"error"}>
              Found an illegal map version, defaulting to {split_map_version}
            </Alert>
          }
          <Map
            center={[0, 0]}
            zoom={5}
            maxZoom={7}
            minZoom={2}
            style={{width: '100%', height: '900px'}}
            ref='mymapforheatmap'
            crs={L.CRS.Simple}
          >
            {
              markers.map((marker) => {
                return <Marker
                  key={`marker-${marker["key"]}`}
                  icon={marker["icon"]}
                  position={marker["position"]}
                  draggable={false}
                  attribution={"CalibratedMarker"}
                >
                  <Popup>
                    {marker["popup"]}
                  </Popup>
                </Marker>
              })
            }
          </Map>
          Map: {map_id} - {map_version}
        </GridItem>
      </GridContainer>
    )
  }
}


function mapStateToProps(state) {
  return {}
}


GreenhouseMap.defaultProps = {
  alternate: "Map could not be generated",
  markers: [],
  coverage_overlay_width: 0.25
}

export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(GreenhouseMap))
